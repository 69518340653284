import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import SaleComps from "./scenes/saleComps";
import AnalysisList from "./scenes/analysis";
import RentComps from "./scenes/rentComps";
import Bar from "./scenes/bar";
import Login from "./components/Login";
import LineRental from "./scenes/lineRental";
import Pie from "./scenes/pie";
import React from "react";
import Map from "./scenes/map";
import DashboardAnalytics from "./scenes/dashboardAnalytics";
import { useState } from "react";
import Register from "./components/Register";
import TokenVerificationWrapper from "./components/TokenVerificationWrapper";
import StripePricingTable from "./components/StripePricingTable";
import { useMediaQuery } from "@mui/material";
import LoginThankYou from "./components/LoginThankYou";

function App() {
  const [theme, colorMode] = useMode();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Use useMediaQuery to listen for changes in screen size
  const isSmall = useMediaQuery("(max-width:700px)");
  const isSmallHeight = useMediaQuery("(max-height:500px)");

  // Update the state based on the screen size
  if (isSmall !== isSmallScreen) {
    setIsSmallScreen(isSmall);
  }

  if (!token) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="*" element={<Register />} />
            <Route path="/pricing" element={<StripePricingTable />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login setToken={setToken} />} />
            <Route
              path="/login-thankyou"
              element={<LoginThankYou setToken={setToken} />}
            />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }
  return (
    <TokenVerificationWrapper>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {isSmall || isSmallHeight ? undefined : <Sidebar />}
            <main className="content">
              <Topbar />
              <Routes>
                <Route path="*" element={<DashboardAnalytics />} />

                <Route path="/sale-comps" element={<SaleComps />} />
                <Route path="/rent-comps" element={<RentComps />} />
                <Route path="/analysis" element={<AnalysisList />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line-rental" element={<LineRental />} />
                <Route path="/map" element={<Map />} />
                <Route path="/area-dashboard" element={<Dashboard />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </TokenVerificationWrapper>
  );
}

export default App;
