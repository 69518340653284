import React, { useState, useContext } from "react";
import { Link } from "@mui/material";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

async function registerUser(credentials) {
  const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
  console.log(baseUrl.concat("/register"));

  return fetch(baseUrl.concat("/register"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

function Register() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMatchColor, setPasswordMatchColor] = useState(
    colors.redAccent[300]
  );
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [lower, setLower] = useState(false);
  const [upper, setUpper] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [length, setLength] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePassword = (e) => {
    setPassword(e.target.value);
    handleLower(e.target.value);
    handleUpper(e.target.value);
    handleNumber(e.target.value);
    handleLength(e.target.value);
    if (password.length > 0 && passwordConfirm.length > 0) {
      setPasswordMatch(e.target.value === passwordConfirm);
    }
  };

  const handlePasswordConfirm = (e) => {
    e.preventDefault();
    // setPasswordConfirm(e.target.value);
    if (e.target.value === password) {
      setPasswordMatch(true);
      setPasswordMatchColor(colors.greenAccent[300]);
    } else {
      setPasswordMatch(false);
    }
    setPasswordConfirm(e.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordMatch && lower && upper && hasNumber && length) {
      try {
        const response = await registerUser({
          email,
          password,
        });

        if (response[1] === 200) {
          localStorage.setItem("customer_id", response[0]["customer_id"]);
          localStorage.setItem("email", response[0]["email"]);

          navigate("/pricing");
        } else {
          setErrorMessage(response[0].message);
          setShowError(true);
        }
        console.log(response);
      } catch (error) {
        setErrorMessage(" Error with Email or Password");
        setShowError(true);
        console.log(errorMessage);
      }
    } else if (!hasNumber) {
      setErrorMessage("Password Needs a Number");
      setShowError(true);
    } else if (!length) {
      setErrorMessage("Password Needs to be 8 characters Long");
      setShowError(true);
    } else if (!upper) {
      setErrorMessage("Password Needs a Upper Case Letter");
      setShowError(true);
    } else if (!lower) {
      setErrorMessage("Password Needs a Lower Case Letter");
      setShowError(true);
    } else {
      setErrorMessage("Passwords Don't Match");
      setShowError(true);
    }
  };

  const handleLower = (pwd) => {
    if (/[a-z]/.test(pwd)) {
      setLower(true);
    } else {
      setLower(false);
    }
  };

  const handleUpper = (pwd) => {
    if (/[A-Z]/.test(pwd)) {
      setUpper(true);
    } else {
      setUpper(false);
    }
  };

  const handleLength = (pwd) => {
    if (pwd.length >= 8) {
      setLength(true);
    } else {
      setLength(false);
    }
  };

  const handleNumber = (pwd) => {
    if (/[0-9]/.test(pwd)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Box></Box>

        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            marginTop={{ xs: "10vh", md: "15vh" }}
            height={{ xs: "60vh", md: "60vh" }}
            width={{ xs: "80vw", md: "28vw" }}
            sx={{
              background: colors.primary[400],
            }}
            // spacing={-2}
          >
            <Grid item xs={12} align="center" paddingTop={2} paddingBottom={2}>
              <Typography
                fontWeight="bold"
                variant="h3"
                color={colors.greenAccent[400]}
              >
                Start Your Free Trial Now!
              </Typography>
            </Grid>

            <Grid item xs={12} align="center">
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.grey[300],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[300],
                    },
                  },
                  "& label.Mui-focused": {
                    color: "white",
                    borderColor: "white",
                  },
                }}
                id="outlined-basic"
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              // paddingLeft={{ xs: 8, md: 16 }}
              align="center"
              paddingBottom={0}
              paddingTop={0}
            >
              <Typography
                fontSize="12px"
                color={
                  hasNumber ? colors.greenAccent[300] : colors.redAccent[300]
                }
              >
                - Use 1 or more numbers
              </Typography>
              <Typography
                fontSize="12px"
                color={lower ? colors.greenAccent[300] : colors.redAccent[300]}
              >
                - Use lower case letters
              </Typography>
              <Typography
                fontSize="12px"
                color={upper ? colors.greenAccent[300] : colors.redAccent[300]}
              >
                - Use upper case letters
              </Typography>
              <Typography
                fontSize="12px"
                color={length ? colors.greenAccent[300] : colors.redAccent[300]}
              >
                - At least 8 characters Long
              </Typography>
              {/* <Typography fontSize="12px">
                - *Extra Credit* use special characters
              </Typography> */}
            </Grid>
            <Grid item xs={12} align="center" marginTop={{ xs: 1, md: 0 }}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.grey[300],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[300],
                    },
                  },
                  "& label.Mui-focused": {
                    color: "white",
                    borderColor: "white",
                  },
                }}
                id="outlined-password-input"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                // onChange={(e) => setPassword(e.target.value)}
                onChange={handlePassword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              // paddingLeft={{ xs: 8, md: 16 }}
              align="center"
            >
              {passwordMatch ? (
                <Typography fontSize="12px" color={colors.greenAccent[300]}>
                  Passwords Match!
                </Typography>
              ) : (
                <Typography fontSize="12px" color={colors.redAccent[300]}>
                  Passwords Do Not Match
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} align="center" marginTop={{ xs: 0, md: 0 }}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.grey[300],
                    },
                    ...(passwordMatch && {
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[300],
                      },
                    }),
                    ...(!passwordMatch && {
                      "&.Mui-focused fieldset": {
                        borderColor: colors.redAccent[300],
                      },
                    }),
                  },
                  "& label.Mui-focused": {
                    color: "white",
                    borderColor: "white",
                  },
                }}
                id="outlined-password-input"
                label="Password Confirm"
                type={showPassword ? "text" : "password"}
                value={passwordConfirm}
                onChange={handlePasswordConfirm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} align="center" paddingTop={0}>
              <Button
                type="submit"
                variant="outlined"
                // width={{ xs: "35vh", md: "20vh" }}
                sx={{
                  background: colors.greenAccent[400],
                  "&:hover": {
                    color: `${colors.greenAccent[300]}!important`,
                    borderColor: `${colors.greenAccent[300]}!important`,
                  },
                  minWidth: "10vw",
                }}
              >
                <Typography fontWeight="bold">Sign Up</Typography>
              </Button>
            </Grid>

            <Grid item xs={12} align="center">
              {showError ? (
                <Typography fontSize="13px" color={colors.redAccent[300]}>
                  {errorMessage}
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item xs={12} align="center">
              <Typography fontWeight="bold">Already Signed Up?</Typography>
              {/* </Grid>
            <Grid item xs={12} align="center"> */}
              <Link
                href="/login"
                underline="hover"
                fontWeight="bold"
                color={colors.blueAccent[300]}
              >
                Log In
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default Register;
