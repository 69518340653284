import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Paper,
  useTheme,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { tokens } from "../theme";

const CustomFilters = ({
  fieldOptions,
  filters,
  setFilters,
  data,
  loading,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [autoKey, setAutoKey] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleFilterReset = () => {
    setFilters({});
    if (autoKey) {
      setAutoKey(false);
    } else {
      setAutoKey(true);
    }
  };

  const handleFilterChange = (field) => (event, option) => {
    if (option.length > 0) {
      let filtersArray = [];
      for (var i = 0; i < option.length; i++) {
        filtersArray.push(option[i].value);
      }

      let { newFilters } = filters;
      if (newFilters === undefined) {
        newFilters = {};
      }

      const key = option[0].field.toString();
      newFilters[key] = filtersArray;

      setFilters(newFilters);
    } else {
      let { newFilters } = filters;
      if (newFilters === undefined) {
        newFilters = {};
      }
      const blankArray = [];
      const fieldKey = field;
      newFilters[fieldKey] = blankArray;
      setFilters(newFilters);
    }
  };

  const getFilterValues = loading
    ? null
    : (field) => {
        try {
          let fieldArray = [];

          for (var i = 0; i < data.length; i++) {
            if (!fieldArray.includes(data[i][field])) {
              fieldArray.push(data[i][field]);
            }
          }

          let finalArray = [];
          for (var i = 0; i < fieldArray.length; i++) {
            let d = {};
            d["value"] = fieldArray[i];
            d["label"] = fieldArray[i].toString();
            d["field"] = field;
            finalArray.push(d);
          }
          return finalArray;
        } catch (error) {
          console.log(error);
        }
      };

  return (
    <Box>
      <Grid
        container
        backgroundColor={colors.primary[400]}
        paddingTop={1}
        justifyContent="center"
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          Filters
        </Typography>
      </Grid>
      <Grid
        container
        backgroundColor={colors.primary[400]}
        padding={1}
        justifyContent="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={{ xs: 2, md: 8 }}
          paddingRight={2}
        >
          {fieldOptions.map((field) => (
            <Grid item xs={6} md={12 / 7} key={field[0]}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.grey[100],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.greenAccent[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <Autocomplete
                  multiple
                  limitTags={1}
                  disableClearable
                  id={`${field[0]}-label`}
                  options={getFilterValues(field[0])}
                  disableCloseOnSelect
                  value={filters[field[0]]} //? filters[field[0]] : null}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  key={autoKey}
                  disablePortal={true}
                  onChange={handleFilterChange(field[0])}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem
                      {...props}
                      key={option.value}
                      value={option.value}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{
                          marginRight: 2,
                          color: colors.greenAccent[300],
                        }}
                        checked={
                          filters[field[0]]
                            ? filters[field[0]].indexOf(option.value) > -1
                            : false
                        }
                      />
                      {option.value}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={field[1]} />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: colors.primary[400] }}>
                      {children}
                    </Paper>
                  )}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} align="center">
          <Button onClick={handleFilterReset}>
            <Typography
              fontSize="12px"
              fontWeight="bold"
              color={colors.blueAccent[300]}
            >
              Reset Filters
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CustomFilters;
