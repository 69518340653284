import { useEffect, useState } from "react";

async function getAnalyticsData(values) {
  const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
  let headerValues = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTONS",
    "Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
  };

  const token = localStorage.getItem("token");

  headerValues["Authorization"] = `Bearer ${token}`;

  const {
    downPayment,
    interestRate,
    loanLength,
    insurancePerSqFt,
    daysOldMax,
    offerDiscount,
    rehabLowCost,
    rehabHighCost,
    propertyTaxes,
  } = values;

  return fetch(
    baseUrl.concat(
      `/analytics/data?filter.root_city=phoenix&interest_rate=${interestRate}&loan_length=${loanLength}&days_old_max=${daysOldMax}&insurance_per_sqft=${insurancePerSqFt}&down_payment=${
        downPayment / 100
      }&offer_discount=${
        offerDiscount / 100
      }&rehab_low=${rehabLowCost}&rehab_high=${rehabHighCost}&property_taxes=${
        propertyTaxes / 100
      }`
    ),
    {
      method: "GET",
      headers: headerValues,
    }
  ).then((data) => data.json());
}

const useAnalysisData = (defaultValues) => {
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // console.log(defaultValues);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      // const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;

      try {
        const response = await getAnalyticsData(defaultValues);
        // const uniqueRecords = uniqueRecords(response);

        setData(response);
        // setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [defaultValues]);

  return { data, loading, error };
};

export default useAnalysisData;
