import React from "react";
import { useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { tokens } from "../theme";
import { getLineFormat } from "../utilities";

const LineChartRental = ({
  isDashboard = false,
  lineData,
  xField,
  yField,
  calcType,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const ref = {
    bedrooms: "Bedroooms",
    bathrooms: "Bathrooms",
    squareFootage: "Square Footage",
    yearBuilt: "Year Built",
    price: "Price",
    mortgagePayment: "Mortgage Payment",
    offerPrice: "Offer Price",
    downPayment: "Down Payment",
    mortgageAmount: "Mortgage Amount",
    rehabCostsLow: "Rehab Costs (Low Estimate)",
    rehabCostsHigh: "Rehab Costs (High Estimate)",
    comps_rent_avg: "Comps Rent Avg",
    comps_listings_avg: "Comps Avg",
    totalPaymentMonthly: "Total Monthly Payment",
    netOpIncomeAnnual: "Net Op Income",
    netIncomeAfterFinancingAnnual: "NIAF",
    average: "Average",
    count: "Count",
    sum: "Sum",
    city: "City",
    zipCode: "Zip Code",
    propertyType: "Property Type",
  };

  function formatLine(records) {
    for (var i = 0; i < records.length; i++) {
      Object.keys(records[i]).forEach(function (key) {
        if (key == "id") {
          const updatedKey = ref[records[i][key]];
          records[i][key] = updatedKey;
        }
      });
    }
    return records;
  }

  const data = formatLine(getLineFormat(lineData, xField, yField, calcType));

  return (
    <ResponsiveLine
      data={data}
      colors={[
        colors.redAccent[400],
        colors.greenAccent[400],
        colors.blueAccent[400],
        colors.redAccent[200],
        colors.greenAccent[200],
        colors.blueAccent[200],
      ]}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
          },
        },
      }}
      enableGridY={false}
      enableSlices="x"
      margin={{ top: 50, right: isDashboard ? 50 : 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=">-$,.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 20,
        legend: isDashboard ? undefined : "City",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Amount",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      lineWidth={5}
      curve="catmullRom"
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableArea={true}
      areaBaselineValue={0}
      areaOpacity={0.1}
      motionConfig="slow"
    />
  );
};

export default LineChartRental;
