import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";

const SingleValBox = ({ title, subtitle, value, value_color = undefined }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (value_color === undefined) {
    value_color = colors.blueAccent[300];
  }

  return (
    <Box width="100%" sx={{ alignItems: "baseline" }}>
      <Box
        // display="flex"
        justifyContent="space-between"
        padding={1}
      >
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[200] }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: colors.grey[300] }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="h1"
          fontWeight="bold"
          sx={{ color: value_color }}
          align="center"
        >
          {value}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" padding={1}></Box>
    </Box>
  );
};

export default SingleValBox;
