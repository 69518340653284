import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import useAnalysisData from "../../hooks/analysisHook";
import LoadingComponent from "../../components/LoadingComponent";
import PieChartDynamic from "../../components/PieChartDynamic";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const Pie = () => {
  const [filters, setFilters] = useState({});
  const theme = useTheme();
  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });
  const colors = tokens(theme.palette.mode);
  const [xValue, setxValue] = useState("city");
  const [yValue, setyValue] = useState("price");
  const [stackedValue, setStackedValue] = useState(null);
  const [AggType, setAggType] = useState("average");
  const [isCurrency, setIsCurrency] = useState(true);
  const [color, setColor] = useState([colors.redAccent, "Red"]);

  const { data, loading, error } = useAnalysisData(inputs);

  const ref = {
    bedrooms: "Bedroooms",
    bathrooms: "Bathrooms",
    squareFootage: "Square Footage",
    yearBuilt: "Year Built",
    price: "Price",
    mortgagePayment: "Mortgage Payment",
    offerPrice: "Offer Price",
    downPayment: "Down Payment",
    mortgageAmount: "Mortgage Amount",
    rehabCostsLow: "Rehab Costs (Low Estimate)",
    rehabCostsHigh: "Rehab Costs (Low Estimate)",
    comps_rent_avg: "Comps Rent Avg",
    comps_listings_avg: "Comps Avg",
    totalPaymentMonthly: "Total Monthly Payment",
    netOpIncomeAnnual: "Net Op Income",
    netIncomeAfterFinancingAnnual: "NIAF",
    average: "Average",
    count: "Count",
    sum: "Sum",
    city: "City",
    zipCode: "Zip Code",
    propertyType: "Property Type",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: colors.primary[400],
      },
    },
  };

  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  function filterData(data, filters) {
    let filteredData = [...data];

    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const handleXChange = (event) => {
    setxValue(event.target.value);
  };

  const handleYChange = (event) => {
    setyValue(event.target.value);
  };

  // const handleStackedChange = (event) => {
  //   setStackedValue(event.target.value);
  // };

  const handleAggChange = (event) => {
    setAggType(event.target.value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setIsCurrency(event.target.value);
  };

  const filteredData = loading ? null : filterData(data, filters);

  return (
    <Box m="20px">
      <Header
        title="Custom Pie Chart"
        subtitle={`${ref[AggType]} of ${ref[yValue]} by ${ref[xValue]}`}
      />

      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={true}
        rehabHighCost={true}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}

      <Grid container padding={1}></Grid>

      <Box>
        <Grid
          container
          backgroundColor={colors.primary[400]}
          paddingTop={1}
          justifyContent="center"
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            Pie Chart Fields and Values
          </Typography>
        </Grid>
        <Grid
          container
          backgroundColor={colors.primary[400]}
          padding={1}
          justifyContent="center"
        >
          <Grid
            // display="flex"
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            // backgroundColor={colors.primary[400]}
            spacing={{ xs: 2, md: 8 }}
            paddingRight={2}
          >
            <Grid item xs={6} md={12 / 5}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="x-value-label">Category</InputLabel>
                <Select
                  labelId="x-value-label"
                  id="x-value"
                  value={xValue}
                  onChange={handleXChange}
                  input={<OutlinedInput label="Category" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={"city"}>City</MenuItem>
                  <MenuItem value={"zipCode"}>Zip Code</MenuItem>
                  <MenuItem value={"propertyType"}>Property Type</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={12 / 5}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="y-value-label">Value</InputLabel>
                <Select
                  labelId="y-value-label"
                  id="y-value"
                  value={yValue}
                  onChange={handleYChange}
                  input={<OutlinedInput label="Value" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={"bedrooms"}>Bedroooms</MenuItem>
                  <MenuItem value={"bathrooms"}>Bathrooms</MenuItem>
                  <MenuItem value={"squareFootage"}>Square Footage</MenuItem>
                  {/* <MenuItem value={"lotSize"}>Lot Size</MenuItem> */}
                  <MenuItem value={"yearBuilt"}>Year Built</MenuItem>
                  <MenuItem value={"price"}>Price</MenuItem>
                  <MenuItem value={"mortgagePayment"}>
                    Mortgage Payment
                  </MenuItem>
                  <MenuItem value={"offerPrice"}>Offer Price</MenuItem>
                  <MenuItem value={"downPayment"}>Down Payment</MenuItem>
                  <MenuItem value={"mortgageAmount"}>Mortgage Amount</MenuItem>
                  <MenuItem value={"rehabCostsLow"}>
                    Rehab Costs (Low Estimate)
                  </MenuItem>
                  <MenuItem value={"rehabCostsHigh"}>
                    Rehab Costs (Low Estimate)
                  </MenuItem>
                  <MenuItem value={"comps_rent_avg"}>Comps Rent Avg</MenuItem>
                  <MenuItem value={"comps_listings_avg"}>Comps Avg</MenuItem>
                  <MenuItem value={"totalPaymentMonthly"}>
                    Total Monthly Payment
                  </MenuItem>
                  <MenuItem value={"netOpIncomeAnnual"}>Net Op Income</MenuItem>
                  <MenuItem value={"netIncomeAfterFinancingAnnual"}>
                    NIAF
                  </MenuItem>
                  {/* <MenuItem value={"cashOnCashReturn"}>CoC Return</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={12 / 5}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="agg-value-label">Calculation</InputLabel>
                <Select
                  labelId="agg-value-label"
                  id="agg-value"
                  value={AggType}
                  onChange={handleAggChange}
                  input={<OutlinedInput label="Calculation" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={"sum"}>Sum</MenuItem>
                  <MenuItem value={"average"}>Average</MenuItem>
                  <MenuItem value={"count"}>Count</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={12 / 5}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="color-value-label">Color</InputLabel>
                <Select
                  labelId="color-value-label"
                  id="color-value"
                  value={color[1]}
                  onChange={handleColorChange}
                  input={<OutlinedInput label="Color" />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={[colors.redAccent, "Red"]}>Red</MenuItem>
                  <MenuItem value={[colors.blueAccent, "Blue"]}>Blue</MenuItem>
                  <MenuItem value={[colors.greenAccent, "Green"]}>
                    Green
                  </MenuItem>
                  <MenuItem value={[colors.grey, "Grey"]}>Grey</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={12 / 5}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="format-label">Format</InputLabel>
                <Select
                  labelId="format-label"
                  id="format"
                  value={isCurrency}
                  onChange={handleCurrencyChange}
                  input={<OutlinedInput label="Format" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={true}>Currency</MenuItem>
                  <MenuItem value={false}>No Format</MenuItem>
                  {/* <MenuItem value={"propertyType"}>Property Type</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          backgroundColor={colors.primary[400]}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <Box
          height="80vh"
          display="flex"
          sx={{
            bgcolor: colors.primary[400],
            overflow: "hidden",
            overflowX: "scroll",
          }}
        >
          <Box
            minWidth={{ xs: "220vw", md: "100vw" }}
            maxWidth="100%"
            height="75vh"
          >
            <Grid item align="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: colors.grey[100] }}
              >
                {`${ref[AggType]} of ${ref[yValue]} by ${ref[xValue]}`}
              </Typography>
            </Grid>
            <PieChartDynamic
              isDashboard={false}
              pieData={filteredData}
              xField={xValue}
              yField={yValue}
              calcType={AggType}
              indexField={stackedValue}
              pieColor={color[0]}
              isCurrency={isCurrency}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Pie;
