import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import Header from "../../components/Header";
import LineChartRental from "../../components/LineChartRental";
import { tokens } from "../../theme";
import Checkbox from "@mui/material/Checkbox";
import useAnalysisData from "../../hooks/analysisHook";
import LoadingComponent from "../../components/LoadingComponent";
import { filterData } from "../../utilities";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const LineRental = () => {
  const [filters, setFilters] = useState({});
  const theme = useTheme();
  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });
  const [xValue, setxValue] = useState("city");
  const [yValue, setyValue] = useState(["price"]);
  const [AggType, setAggType] = useState("average");
  const colors = tokens(theme.palette.mode);

  const { data, loading, error } = useAnalysisData(inputs);

  const ref = {
    bedrooms: "Bedroooms",
    bathrooms: "Bathrooms",
    squareFootage: "Square Footage",
    yearBuilt: "Year Built",
    price: "Price",
    mortgagePayment: "Mortgage Payment",
    offerPrice: "Offer Price",
    downPayment: "Down Payment",
    mortgageAmount: "Mortgage Amount",
    rehabCostsLow: "Rehab Costs (Low Estimate)",
    rehabCostsHigh: "Rehab Costs (High Estimate)",
    comps_rent_avg: "Comps Rent Avg",
    comps_listings_avg: "Comps Avg",
    totalPaymentMonthly: "Total Monthly Payment",
    netOpIncomeAnnual: "Net Op Income",
    netIncomeAfterFinancingAnnual: "NIAF",
    average: "Average",
    count: "Count",
    sum: "Sum",
    city: "City",
    zipCode: "Zip Code",
    propertyType: "Property Type",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: colors.primary[400],
      },
    },
  };

  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  const yOptions = [
    ["bedrooms", "Bedroooms"],
    ["bathrooms", "Bathrooms"],
    ["squareFootage", "Square Footage"],
    ["yearBuilt", "Year Built"],
    ["price", "Price"],
    ["mortgagePayment", "Mortgage Payment"],
    ["offerPrice", "Offer Price"],
    ["downPayment", "Down Payment"],
    ["mortgageAmount", "Mortgage Amount"],
    ["rehabCostsLow", "Rehab Costs (Low Estimate)"],
    ["rehabCostsHigh", "Rehab Costs (High Estimate)"],
    ["comps_rent_avg", "Comps Rent Avg"],
    ["comps_listings_avg", "Comps Avg"],
    ["totalPaymentMonthly", "Total Monthly Payment"],
    ["netOpIncomeAnnual", "Net Op Income"],
    ["netIncomeAfterFinancingAnnual", "NIAF"],
  ];

  const handleXChange = (event) => {
    setxValue(event.target.value);
  };

  const handleYChange = (event) => {
    setyValue(event.target.value);
  };

  const handleAggChange = (event) => {
    setAggType(event.target.value);
  };

  const filteredData = loading ? null : filterData(data, filters);

  return (
    <Box m="20px">
      <Header
        title="Custom Line Chart"
        subtitle={`${ref[AggType]} of ${yValue
          .map((y) => ref[y])
          .join(", ")} by ${ref[xValue]}`}
      />

      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={true}
        rehabHighCost={true}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}

      <Grid container padding={1}></Grid>

      <Box>
        <Grid
          container
          backgroundColor={colors.primary[400]}
          paddingTop={1}
          justifyContent="center"
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            Line Chart Fields and Values
          </Typography>
        </Grid>
        <Grid
          container
          backgroundColor={colors.primary[400]}
          padding={1}
          justifyContent="center"
        >
          <Grid
            // display="flex"
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            // backgroundColor={colors.primary[400]}
            spacing={{ xs: 2, md: 8 }}
            paddingRight={2}
          >
            <Grid item xs={6} md={4}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="x-value-label">Category</InputLabel>
                <Select
                  labelId="x-value-label"
                  id="x-value"
                  value={xValue}
                  onChange={handleXChange}
                  input={<OutlinedInput label="Category" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={"city"}>City</MenuItem>
                  <MenuItem value={"zipCode"}>Zip Code</MenuItem>
                  <MenuItem value={"propertyType"}>Property Type</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="y-value-label">Value</InputLabel>
                <Select
                  labelId="y-value-label"
                  id="y-value"
                  multiple
                  value={yValue}
                  onChange={handleYChange}
                  input={<OutlinedInput label="Value" />}
                  renderValue={
                    (selected) => selected.map((y) => ref[y]).join(", ")
                    // selected.join(", ")
                  }
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  {yOptions.map((field) => (
                    <MenuItem value={field[0]}>
                      <Checkbox
                        checked={yValue.indexOf(field[0]) > -1}
                        sx={{
                          "&.Mui-checked": {
                            color: colors.greenAccent[400],
                          },
                        }}
                      />
                      <ListItemText primary={field[1]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.primary[200],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[400],
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.primary[100],
                    borderColor: colors.grey[100],
                  },
                  minWidth: "100%",
                  maxWidth: "100%",
                  m: 1,
                }}
              >
                <InputLabel id="agg-value-label">Calculation</InputLabel>
                <Select
                  labelId="agg-value-label"
                  id="agg-value"
                  value={AggType}
                  onChange={handleAggChange}
                  input={<OutlinedInput label="Calculation" />}
                  // renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={
                    {
                      // maxWidth: "10vw",
                      // maxHeight: "4vh",
                      // minWidth: "10vw",
                      // minHeight: "4vh",
                      // m: 1,
                    }
                  }
                >
                  <MenuItem value={"sum"}>Sum</MenuItem>
                  <MenuItem value={"average"}>Average</MenuItem>
                  <MenuItem value={"count"}>Count</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          backgroundColor={colors.primary[400]}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <Box
          height="80vh"
          display="flex"
          sx={{
            bgcolor: colors.primary[400],
            overflow: "hidden",
            overflowX: "scroll",
          }}
        >
          <Box
            minWidth={{ xs: "220vw", md: "100vw" }}
            maxWidth="100%"
            height="75vh"
          >
            <Grid item align="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: colors.grey[100] }}
              >
                {`${ref[AggType]} of ${yValue
                  .map((y) => ref[y])
                  .join(", ")} by ${ref[xValue]}`}
                {/* {`${ref[AggType]} of ${ref[yValue]} by ${ref[xValue]}`} */}
              </Typography>
            </Grid>
            <LineChartRental
              isDashboard={false}
              lineData={filteredData}
              xField={xValue}
              yField={yValue}
              calcType={AggType}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LineRental;
