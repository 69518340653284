import React from "react";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Grid from "@mui/material/Grid";
import { Dialog, Button, Box } from "@mui/material";
import "../index.css";

function Popup({ isOpen, portalUrl }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Dialog open={isOpen}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        backgroundColor={colors.primary[400]}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="20vh"
          width="1"
        >
          <Grid item xs={12} align="center">
            <Typography color={colors.greenAccent[300]} variant="h3">
              Ooops!
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography color={colors.grey[100]} variant="h5">
              Your account is currently inactive. No Worries! Let's Check on
              your subscription status.
            </Typography>
          </Grid>

          <Grid item xs={12} align="center">
            <Button
              onClick={() => (window.location.href = portalUrl)}
              sx={{
                background: colors.greenAccent[400],
                fontWeight: "bold",
                "&:hover": {
                  color: `${colors.greenAccent[300]}!important`,
                  borderColor: `${colors.greenAccent[300]}!important`,
                },
                minWidth: "10vw",
              }}
              variant="outlined"
            >
              Manage Subscription
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default Popup;
