import React, { useState } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { getBool, getCurrency } from "../../utilities/index";
import CustomToolbar from "../../components/CustomGridToolbar";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import useListingData from "../../hooks/listingHook";
import LoadingComponent from "../../components/LoadingComponent";
import { formatComps } from "../../utilities/index";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const SaleComps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [filters, setFilters] = useState({});

  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });
  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  const { data, loading, error } = useListingData(inputs);

  function filterData(data, filters) {
    let filteredData = [...data];
    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const filteredData = loading ? null : filterData(data, filters);

  const columns = [
    {
      field: "id",
      headerName: "Target Address",
      width: 250,
      description: "The URL of the Zillow listing of the target property",
      renderCell: (params) => (
        <Button
          variant="contained"
          display="flex"
          onClick={() => {
            window.open(`https://www.zillow.com/homes/${params.value}_rb`);
          }}
          sx={{ background: colors.greenAccent[500] }}
          endIcon={<LaunchOutlinedIcon />}
        >
          <Typography fontWeight="bold">
            {params.value.split(",")[0].split("-").join(" ")}
          </Typography>
        </Button>
      ),
    },
    {
      field: "city",
      headerName: "City",
      description:
        "The shared city of both the target property and the comp property",
    },
    {
      field: "state",
      headerName: "State",
      description:
        "The shared state of both the target property and the comp property",
    },

    {
      field: "propertyType",
      headerName: "Property Type",
      description: "The type of property",
      width: 150,
    },
    {
      field: "bedrooms",
      headerName: "Beds",
      description: "The number of bedrooms",
    },
    {
      field: "bathrooms",
      headerName: "Baths",
      description:
        "The number of bathrooms in both the target property and comp property",
    },
    {
      field: "squareFootage",
      headerName: "Target Sqft",
      description: "The square footage of the target property",
      width: 150,
    },
    {
      field: "price",
      headerName: "Target Price",
      description: "The target property current listing price",
      width: 150,
      valueFormatter: (params) => getCurrency(params.value),
    },
    {
      field: "compDMiles",
      headerName: "Comp Dist",
      width: 150,
      description:
        "The distance in miles between the target property and the comp property",
      valueFormatter: (params) =>
        params.value ? Number(params.value.toFixed(2)) : 0,
    },
    {
      field: "compSquareFootage",
      headerName: "Comp Sq ft",
      description: "The square footage of the comp property",
      width: 150,
    },
    {
      field: "compPrice",
      headerName: "Comp Price",
      description:
        "The comp property listing price or sold price if the property was sold",
      valueFormatter: (params) => getCurrency(params.value),
      width: 150,
    },

    {
      field: "compSold",
      headerName: "Comp Sold?",
      valueGetter: (params) => getBool(params.row.compSold),
      description:
        "Has the comp been sold, only comps that have been sold are allowed within 180 days of today's date",
      width: 150,
    },
    {
      field: "compId",
      headerName: "Comp Zillow URL",
      width: 250,
      description: "The URL of the Zillow listing of the comp property",
      renderCell: (params) => (
        <Button
          variant="contained"
          display="flex"
          onClick={() => {
            window.open(`https://www.zillow.com/homes/${params.value}_rb`);
          }}
          sx={{ background: colors.greenAccent[500] }}
          endIcon={<LaunchOutlinedIcon />}
        >
          <Typography fontWeight="bold">
            {params.value.split(",")[0].split("-").join(" ")}
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="For Sale Comp Data"
        subtitle="List of Currently For Sale and Recently Sold Properties within proximity of the Target Homes"
      />
      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={false}
        rehabHighCost={false}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}
      <Grid container padding={1}></Grid>

      <Box m="40px 0 0 0" height="75vh" width="100%">
        {loading ? (
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        ) : (
          <DataGrid
            slots={{ toolbar: CustomToolbar }}
            rows={formatComps(filteredData, "comps_listings")}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(row) => row.id + row.compId}
            columnBuffer={3}
            columnThreshold={3}
          />
        )}
      </Box>
    </Box>
  );
};
export default SaleComps;
