import { useEffect, useState } from "react";
import { styled } from "@mui/material";

function ZillowImage(url) {
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log(url.url);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CORS_ANYWHERE + url.url + "/",
          { headers: { "Access-Control-Allow-Origin": "*" } }
        );
        const data = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/html");
        console.log(data);
        const image =
          doc
            .querySelector('meta[property="og:image"]')
            ?.getAttribute("content") || "";
        setPreviewData({ image });
        setLoading(false);
        console.log({ image });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);

  const Img = styled("img")({
    // margin: "auto",
    maxWidth: "75%",
    maxHeight: "100%",
  });

  if (loading) {
    return <p>Loading...</p>;
  }
  if (!previewData) {
    return <img src={process.env.PUBLIC_URL + "/assets/zillow.png"} />;
  }

  return <Img src={previewData.image} />;
}

export default ZillowImage;
