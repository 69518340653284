import React, { useState } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import { Collapse, Typography } from "@mui/material";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuList from "../../components/MenuList";
import ProfileDropdownMenu from "../../components/ProfileDropdownMenu";
import { useMediaQuery } from "@mui/material";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  // const handleLinkClick = () => {
  //   setOpen(false); // Collapse the Collapse component when a link is clicked
  // };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const [isSmallHeight, setIsSmallHeight] = useState(false);

  // Use useMediaQuery to listen for changes in screen size
  const isSmall = useMediaQuery("(max-width:700px)");
  const isSmallHeight = useMediaQuery("(max-height:500px)");

  // Update the state based on the screen size
  if (isSmall !== isSmallScreen) {
    setIsSmallScreen(isSmall);
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" p={2}>
        {/* SEARCH BAR */}

        <Box
          display="flex"
          // backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          {isSmall || isSmallHeight ? (
            <IconButton onClick={toggleMenu}>
              <MenuOutlinedIcon />
            </IconButton>
          ) : undefined}

          {isSmall || isSmallHeight ? (
            <Box
              justifyContent="center"
              paddingLeft={2}
              paddingRight={2}
              paddingTop={0.5}
            >
              <Typography
                variant="h4"
                color={colors.greenAccent[300]}
                fontWeight="bold"
              >
                Propertana
              </Typography>
            </Box>
          ) : undefined}
        </Box>

        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>

          <ProfileDropdownMenu />
          <IconButton onClick={logout}>
            <LogoutOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <MenuList setOpen={setOpen} />
      </Collapse>
    </Box>
  );
};

export default Topbar;
