import React from "react";
import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import Avatar from "@mui/material/Avatar";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useMediaQuery } from "@mui/material";
import LoadingComponent from "../../components/LoadingComponent";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

function StringAvatar(name) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return {
    sx: {
      bgcolor: colors.grey[300],
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const [errorMessage,setErrorMessage] = useState('')
  // const [showError,setShowError] = useState(false)
  const [customerName, setCustomerName] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [loading, setLoading] = useState(true);

  // Use useMediaQuery to listen for changes in screen size
  const isSmall = useMediaQuery("(max-width:700px)");

  // Update the state based on the screen size
  if (isSmall !== isSmallScreen) {
    setIsSmallScreen(isSmall);
    setIsCollapsed(true);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
        // console.log(baseUrl);
        const customerId = localStorage.getItem("customer_id");
        const response = await fetch(
          baseUrl.concat(`/subscription-details/${customerId}`),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTONS",
              "Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
            },
          }
        );
        const responseData = await response.json();
        setLoading(false);

        if (responseData[1] !== 200) {
          throw new Error("Failed to fetch data");
        }
        if (
          responseData[0]["data"]["customer_name"] === null ||
          responseData[0]["data"]["customer_name"] === undefined ||
          responseData[0]["data"]["customer_name"] === ""
        ) {
          setCustomerName("Trial Account");
        } else {
          setCustomerName(responseData[0]["data"]["customer_name"]);
        }
        setSubscriptionName(responseData[0]["data"]["subscription_name"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 25px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed} width="22vw">
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="7vw"
              >
                <Typography
                  variant="h3"
                  color={colors.greenAccent[300]}
                  fontWeight="bold"
                >
                  Propertana
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {/* USER */}
          {!isCollapsed &&
            (loading ? (
              <Grid
                container
                justifyContent="center"
                sx={{ bgcolor: colors.primary[400] }}
              >
                <LoadingComponent circleColor={colors.greenAccent[400]} />
              </Grid>
            ) : (
              <Box mb="25px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  ml="15px"
                >
                  <Item
                    to="/profile"
                    icon={<Avatar {...StringAvatar(customerName)} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Box>

                <Box textAlign="center">
                  <Typography
                    variant="h3"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0 " }}
                  >
                    {customerName}
                  </Typography>

                  <Typography variant="h5" color={colors.greenAccent[500]}>
                    {subscriptionName}
                  </Typography>
                </Box>
              </Box>
            ))}
          {/* Menu Items */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {isCollapsed ? (
              <Typography
                variant="h6"
                colors={colors.grey[600]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Boards
              </Typography>
            ) : (
              <Typography
                variant="h6"
                colors={colors.grey[600]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Dashboards
              </Typography>
            )}
            <Item
              title="Investment Analysis Dashboard"
              to="/"
              icon={<AnalyticsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Area Analysis Dashboard"
              to="/area-dashboard"
              icon={<AnalyticsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Tables
            </Typography>

            <Item
              title="For Sale Comp Data"
              to="/sale-comps"
              icon={<TableChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Rent Comp Data"
              to="/rent-comps"
              icon={<TableChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Investment Analysis Data"
              to="/analysis"
              icon={<TableChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>

            <Item
              title="Bar Chart Analysis"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart Analysis"
              to="/pie"
              icon={<PieChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart Analysis"
              to="/line-rental"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Map Analysis"
              to="/map"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}

            {/* <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ"
              to="/faq"
              icon={<HelpOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
