import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import Header from "../../components/Header";
import { getCurrency } from "../../utilities/index";
import CustomToolbar from "../../components/CustomGridToolbar";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { tokens } from "../../theme";
import useRentalData from "../../hooks/rentalHook";
import LoadingComponent from "../../components/LoadingComponent";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const RentComps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filters, setFilters] = useState({});
  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });
  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  function formatRent(rentData) {
    const keys = [
      "propertyType",
      "bedrooms",
      "bathrooms",
      "squareFootage",
      "lotSize",
      "price",
      "city",
      "state",
      "id",
      "zipCode",
      "county",
      "mortgagePayment",
    ];

    let flatData = [];

    for (var i = 0; i < rentData.length; i++) {
      let baseDict = {};

      Object.keys(rentData[i]).forEach(function (key) {
        if (keys.includes(key)) {
          baseDict[key] = rentData[i][key];
        }
      });

      baseDict["formattedAddress"] = rentData[i]["id"].split("-").join(" ");

      let rentArray = rentData[i]["comps_rent"];

      if (rentArray) {
        for (var j = 0; j < rentArray.length; j++) {
          let rentalDict = { ...baseDict };

          Object.keys(rentArray[j]).forEach(function (rentKey) {
            const newKey =
              "rentComp" + rentKey.charAt(0).toUpperCase() + rentKey.slice(1);

            rentalDict[newKey] = rentArray[j][rentKey];
          });
          flatData.push(rentalDict);
        }
      }
    }
    return flatData;
  }

  const { data, loading, error } = useRentalData(inputs);

  function filterData(data, filters) {
    let filteredData = [...data];
    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const filteredData = loading ? null : filterData(data, filters);

  const columns = [
    {
      field: "id",
      headerName: "Target Zillow URL",
      width: 250,
      description: "The URL of the Zillow listing of the target property",
      renderCell: (params) => (
        <Button
          variant="contained"
          display="flex"
          onClick={() => {
            window.open(`https://www.zillow.com/homes/${params.value}_rb`);
          }}
          sx={{ background: colors.greenAccent[500] }}
          endIcon={<LaunchOutlinedIcon />}
        >
          <Typography fontWeight="bold">
            {params.value.split(",")[0].split("-").join(" ")}
          </Typography>
        </Button>
      ),
    },
    {
      field: "city",
      headerName: "City",
      description:
        "The shared city of both the target property and the comp property",
    },
    {
      field: "state",
      headerName: "State",
      description:
        "The shared state of both the target property and the comp property",
    },

    {
      field: "propertyType",
      headerName: "Property Type",
      description: "The type of property",
      width: 150,
    },
    {
      field: "bedrooms",
      headerName: "Beds",
      description:
        "The number of bedrooms in both the target property and comp property",
    },
    {
      field: "bathrooms",
      headerName: "Baths",
      description:
        "The number of bathrooms in both the target property and comp property",
    },
    {
      field: "squareFootage",
      headerName: "Target Sqft",
      description: "The square footage of the target property",
      width: 150,
    },

    {
      field: "price",
      headerName: "Target Listing Price",
      description:
        "Estimated Monthly Payment considering interest rate of 6%, 20% down payment, insurance, taxes, and mortgage costs",
      valueFormatter: (params) => getCurrency(params.value),
      width: 150,
    },
    {
      field: "mortgagePayment",
      headerName: "Est Monthly Payment",
      description:
        "Estimated Monthly Payment considering interest rate of 6%, 20% down payment, insurance, taxes, and mortgage costs",
      valueFormatter: (params) => getCurrency(params.value),
      width: 150,
    },
    {
      field: "rentCompDMiles",
      headerName: "Comp Distance",
      width: 150,
      description:
        "The distance in miles between the target property and the comp property",
      valueFormatter: (params) => Number(params.value.toFixed(2)),
    },
    {
      field: "rentCompSquareFootage",
      headerName: "Comp Sq ft",
      description: "The square footage of the comp property",
      width: 150,
    },
    {
      field: "rentCompPrice",
      headerName: "Comp Monthly Rent",
      description:
        "The comp property listing price or sold price if the property was sold",
      valueFormatter: (params) => getCurrency(params.value),
      width: 150,
    },
    {
      field: "rentCompId",
      headerName: "Comp Zillow URL",
      width: 250,
      description: "The URL of the Zillow listing of the comp property",
      renderCell: (params) => (
        <Button
          variant="contained"
          display="flex"
          onClick={() => {
            window.open(`https://www.zillow.com/homes/${params.value}_rb`);
          }}
          sx={{ background: colors.greenAccent[500] }}
          endIcon={<LaunchOutlinedIcon />}
        >
          <Typography fontWeight="bold">
            {params.value.split(",")[0].split("-").join(" ")}
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Rent Comp Data"
        subtitle="List of Currently For Rent Properties within proximity of the Target Property"
      />

      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={false}
        rehabHighCost={false}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}
      <Grid container padding={1}></Grid>

      <Box m="40px 0 0 0" height="75vh" width="100%">
        {loading ? (
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        ) : (
          <DataGrid
            slots={{ toolbar: CustomToolbar }}
            rows={formatRent(filteredData)}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(row) => row.id + row.rentCompId}
            columnBuffer={3}
            columnThreshold={3}
          />
        )}
      </Box>
    </Box>
  );
};
export default RentComps;
