import React from "react";
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { getPieFormat } from "../utilities";

function GetColor(color, colorCode) {
  const colorMultiple = color * 100;
  return colorCode[colorMultiple];
}

const PieChartDynamic = ({
  isDashboard = false,
  pieData,
  xField,
  yField,
  calcType,
  pieColor,

  isCurrency = true,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = getPieFormat(pieData, xField, yField, calcType);
  return (
    <Box height="100%" width="100%">
      <ResponsivePie
        data={data}
        theme={{
          annotations: {
            text: {
              fill: colors.grey[700],
            },
          },
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
          tooltip: {
            container: {
              background: colors.primary[400],
            },
          },
        }}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        colors={(d) => GetColor(d.data.color, pieColor)}
        innerRadius={0.5}
        padAngle={0.5}
        valueFormat={isCurrency ? ">$," : undefined}
        cornerRadius={5}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor={colors.grey[100]}
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={12.75}
        arcLabelsTextColor={colors.grey[900]}
        motionConfig="slow"
      />
    </Box>
  );
};

export default PieChartDynamic;
