import React, { useState, useRef } from "react";
import Header from "../../components/Header";
import LoadingComponent from "../../components/LoadingComponent";
// import InputAdornment from "@mui/material/InputAdornment";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  // TextField,
  // InputLabel,
  // FormControl,
  // MenuItem,
  // Select,
  // OutlinedInput,
  // ListItemText,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LineChartRental from "../../components/LineChartRental";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { getStatBoxVals } from "../../utilities";
import CircularProgress from "@mui/material/CircularProgress";
import PieChartDynamic from "../../components/PieChartDynamic";
// import Checkbox from "@mui/material/Checkbox";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useListingData from "../../hooks/listingHook";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filters, setFilters] = useState({});
  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });
  const { data, loading, error } = useListingData(inputs);

  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  function filterData(data, filters) {
    let filteredData = [...data];
    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const filteredData = loading ? null : filterData(data, filters);

  // 1st row calculations
  const price = loading ? null : getStatBoxVals(filteredData, "price");
  const offer = loading ? null : getStatBoxVals(filteredData, "offerPrice");
  const dp = loading ? null : getStatBoxVals(filteredData, "downPayment");
  const mortgAmt = loading
    ? null
    : getStatBoxVals(filteredData, "mortgageAmount");

  const isSmall = useMediaQuery("(max-width:700px)");

  const pdfRef = useRef();
  const downloadPDF = () => {
    const input = pdfRef.current;

    console.log(input);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const ratioW = pdfWidth / imgWidth;
      const ratioH = pdfHeight / imgHeight;
      const ratio = Math.min(ratioW, ratioH);
      const imgX = (pdfWidth - imgWidth * ratio * 0.9) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio * 0.9,
        imgHeight * ratio * 1.1
      );
      pdf.save("Dashboard.pdf");
    });
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="AREA ANALYSIS DASHBOARD"
          subtitle="Get area specific insights to existing listings"
        />
      </Box>
      <Box>
        <Button
          type="submit"
          variant="outlined"
          sx={{
            float: "right",
            background: colors.greenAccent[500],
            "&:hover": {
              color: `${colors.greenAccent[300]}!important`,
              borderColor: `${colors.greenAccent[300]}!important`,
            },
          }}
          onClick={downloadPDF}
        >
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
          <Typography sx={{ fontWeight: "bold" }}>Download Reports</Typography>
        </Button>
      </Box>

      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={false}
        rehabHighCost={false}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}

      <Grid container padding={1}></Grid>
      <Box ref={pdfRef}>
        <Grid container paddingTop={1}>
          {/* Row 1 */}
          <Grid
            item
            xs={12}
            md={2.8}
            //   spacing={1}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            padd
          >
            {loading ? (
              <Box sx={{ color: colors.greenAccent[400] }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <StatBox
                title="Average Property List Price"
                subtitle="Average of all Properties for Sale"
                min={price.min}
                max={price.max}
                value={price.avg}
              />
            )}
          </Grid>

          <Grid item xs={12} md={0.25} p={0.5}></Grid>

          <Grid
            item
            xs={12}
            md={2.8}
            //   spacing={2}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <Box sx={{ color: colors.greenAccent[400] }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <StatBox
                title="Average Offer Price"
                subtitle={`Considering Offer is discounted ${inputs["offerDiscount"]}%`}
                min={offer.min}
                max={offer.max}
                value={offer.avg}
              />
            )}
          </Grid>

          <Grid item xs={12} md={0.25} p={0.5}></Grid>

          <Grid
            item
            xs={12}
            md={2.8}
            //   spacing={1}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <Box sx={{ color: colors.greenAccent[400] }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <StatBox
                title="Down Payment"
                subtitle={`Considering ${inputs["downPayment"]}% of the Offer Price`}
                min={dp.min}
                max={dp.max}
                value={dp.avg}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            //   spacing={1}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <Box sx={{ color: colors.greenAccent[400] }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <StatBox
                title="Mortgage Amount"
                subtitle={`Considering ${inputs["offerDiscount"]}% discount on List Price and ${inputs["downPayment"]}% down payment`}
                min={mortgAmt.min}
                max={mortgAmt.max}
                value={mortgAmt.avg}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} backgroundColor={colors.primary[400]}>
          <Grid height={{ xs: "55vh", md: "60vh" }}>
            <Box sx={{ bgcolor: colors.primary[400] }}>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Average List, Offer, and Mortgage Cost by City
              </Typography>
            </Box>
            {loading ? (
              <Grid
                container
                justifyContent="center"
                backgroundColor={colors.primary[400]}
              >
                <LoadingComponent circleColor={colors.greenAccent[400]} />
              </Grid>
            ) : (
              <Box
                height="50vh"
                display="flex"
                sx={
                  isSmall
                    ? {
                        bgcolor: colors.primary[400],
                        overflow: "hidden",
                        overflowX: "scroll",
                      }
                    : {
                        bgcolor: colors.primary[400],
                        // overflow: "hidden",
                        // overflowX: "scroll",
                      }
                }
              >
                <Box
                  minWidth={{ xs: "175vw", md: "100vw" }}
                  maxWidth="100%"
                  height="50vh"
                >
                  <LineChartRental
                    isDashboard={true}
                    lineData={filteredData}
                    xField={"city"}
                    yField={["price", "offerPrice", "mortgageAmount"]}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid xs={12} md={0} p={0.5}></Grid>

        <Grid container paddingTop={2} justifyContent="center">
          <Grid item xs={12} md={5.875} backgroundColor={colors.primary[400]}>
            <Grid>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Average Listing Price by City
              </Typography>
            </Grid>
            <Grid height="55vh">
              {loading ? (
                <Grid container justifyContent="center">
                  <Box
                    sx={{ color: colors.greenAccent[400] }}
                    justifyContent="center"
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                </Grid>
              ) : (
                <Box
                  height="55vh"
                  display="flex"
                  sx={
                    isSmall
                      ? {
                          bgcolor: colors.primary[400],
                          overflow: "hidden",
                          overflowX: "scroll",
                        }
                      : {
                          bgcolor: colors.primary[400],
                          // overflow: "hidden",
                          // overflowX: "scroll",
                        }
                  }
                >
                  <Box
                    minWidth={{ xs: "220vw", md: "50vw" }}
                    maxWidth="100%"
                    height="55vh"
                  >
                    <PieChartDynamic
                      pieData={filteredData}
                      xField={"city"}
                      yField={"price"}
                      calcType={"average"}
                      pieColor={colors.redAccent}
                      isCurrency={true}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid item xs={12} md={5.875} backgroundColor={colors.primary[400]}>
            <Grid>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Average Days on the Market
              </Typography>
            </Grid>
            <Grid height="55vh">
              {loading ? (
                <Grid container justifyContent="center">
                  <Box
                    sx={{ color: colors.greenAccent[400] }}
                    justifyContent="center"
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                </Grid>
              ) : (
                <Box
                  height="55vh"
                  display="flex"
                  sx={
                    isSmall
                      ? {
                          bgcolor: colors.primary[400],
                          overflow: "hidden",
                          overflowX: "scroll",
                        }
                      : {
                          bgcolor: colors.primary[400],
                          // overflow: "hidden",
                          // overflowX: "scroll",
                        }
                  }
                >
                  <Box
                    minWidth={{ xs: "220vw", md: "50vw" }}
                    maxWidth="100%"
                    height="55vh"
                  >
                    <PieChartDynamic
                      pieData={filteredData}
                      xField={"city"}
                      yField={"daysOnMarket"}
                      calcType={"average"}
                      pieColor={colors.blueAccent}
                      isCurrency={false}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item backgroundColor={colors.primary[400]}>
          <Grid>
            <Typography
              variant="h3"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "10px" }}
            >
              Number of Properties Available by City
            </Typography>
          </Grid>

          {loading ? (
            <Grid height="50vh">
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            </Grid>
          ) : (
            <Box
              height="55vh"
              display="flex"
              sx={
                isSmall
                  ? {
                      bgcolor: colors.primary[400],
                      overflow: "hidden",
                      overflowX: "scroll",
                    }
                  : {
                      bgcolor: colors.primary[400],
                      // overflow: "hidden",
                      // overflowX: "scroll",
                    }
              }
            >
              <Box
                minWidth={{ xs: "200vw", md: "100vw" }}
                maxWidth="100%"
                height="50vh"
              >
                <BarChart
                  isDashboard={true}
                  barData={filteredData}
                  xField={"city"}
                  yField={"id"}
                  calcType={"count"}
                  indexField={null}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
