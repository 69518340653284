import React from "react";

export function getBool(rowVal) {
  if (rowVal !== true) {
    return false;
  } else {
    return true;
  }
}

export function getCurrency(num) {
  if (num) {
    const formattedNum = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formattedNum.split(".")[0];
  } else {
    return "";
  }
}

export function GetZillowUrl(zid, urlColor) {
  const address = zid.split(",")[0].split("-").join(" ");

  return (
    <a
      href={`https://www.zillow.com/homes/${zid}_rb`}
      style={{ color: urlColor }}
    >
      {address}
    </a>
  );
}

export function dateFormat(dateVal) {
  if (dateVal !== null) {
    const dateObj = new Date(dateVal);
    const d = dateObj.getDate();
    const m = dateObj.getMonth() + 1;
    const y = dateObj.getFullYear().toString().substr(-2);

    return `${m}/${d}/${y}`;
  } else {
    return "";
  }
}

export function getPercent(rateVal) {
  const rateValConv = rateVal * 100;
  return `${rateValConv.toFixed(2)} %`;
}

export function getStatBoxVals(data, key, annual_to_monthly = false, format) {
  let currArray = [];
  let val = 0;

  data.forEach((dict) => {
    val = annual_to_monthly ? dict[key] / 12 : dict[key];
    currArray.push(val);
  });

  if (format === "p") {
    const min = getPercent(Math.min(...currArray));
    const max = getPercent(Math.max(...currArray));
    const sum = currArray.reduce((acc, curr) => acc + curr, 0);
    const avg = getPercent(sum / currArray.length);
    return { min: min, max: max, avg: avg };
  } else {
    const min = getCurrency(Math.min(...currArray));
    const max = getCurrency(Math.max(...currArray));
    const sum = currArray.reduce((acc, curr) => acc + curr, 0);
    const avg = getCurrency(sum / currArray.length);
    return { min: min, max: max, avg: avg };
  }
}

export function getPositiveCashFlow(data) {
  let currArray = [];
  let idCache = [];
  data.forEach((dict) => {
    if (dict["netIncomeAfterFinancingAnnual"] > 0) {
      if (!idCache.includes(dict.id)) {
        currArray.push(dict);
        idCache.push(dict.id);
      }
    }
  });
  return currArray;
}

export function getPieFormat(data, x_field, y_field, calcType) {
  let tempDict = {};

  for (var i = 0; i < data.length; i++) {
    if (!data[i][x_field] || !data[i][y_field]) {
      continue;
    } else {
      if (data[i][x_field] in tempDict) {
        let valArray = tempDict[data[i][x_field]];
        valArray.push(data[i][y_field]);
      } else {
        let tempArray = [];
        tempArray.push(data[i][y_field]);
        tempDict[data[i][x_field]] = tempArray;
      }
    }
  }

  let returnedArray = [];

  Object.keys(tempDict).forEach(function (key) {
    let indDict = {};
    indDict["id"] = key;
    indDict["label"] = key;
    if (calcType === "sum") {
      var sumValues = tempDict[key].reduce((tSum, vals) => tSum + vals, 0);
      indDict["value"] = sumValues;
    } else if (calcType === "count") {
      var count = tempDict[key].length;
      indDict["value"] = count;
    } else {
      var sumValues = tempDict[key].reduce((tSum, vals) => tSum + vals, 0);
      var avgValues = Math.round(sumValues / tempDict[key].length);
      indDict["value"] = avgValues;
    }
    returnedArray.push(indDict);
  });

  const totalValues = returnedArray.reduce(
    (valuesSum, dict) => valuesSum + dict["value"],
    0
  );
  var colorSum = 0;

  returnedArray.sort((val1, val2) => val1.value - val2.value);

  for (var j = 0; j < returnedArray.length; j++) {
    var ratio = returnedArray[j]["value"] / totalValues;
    colorSum += ratio;
    var finalColor = Math.round(0.7 * colorSum * 10);
    if (finalColor === 0) {
      returnedArray[j]["color"] = 1;
    } else {
      returnedArray[j]["color"] = finalColor;
    }
  }

  return returnedArray;
}

export function getLineFormat(data, x_field, y_field, calcType) {
  let colors = ["r", "g", "b"];
  let dictValues = {};
  // console.log(y_field);

  for (var y = 0; y < y_field.length; y++) {
    let tempDict = {};
    dictValues[y_field[y]] = tempDict;
  }

  for (var i = 0; i < data.length; i++) {
    for (var j = 0; j < y_field.length; j++) {
      let record = data[i];
      let x = record[x_field];
      if (dictValues[y_field[j]][x]) {
        dictValues[y_field[j]][x].push(data[i][y_field[j]]);
      } else {
        let tempArray = [];
        tempArray.push(data[i][y_field[j]]);
        dictValues[y_field[j]][x] = tempArray;
      }
    }
  }

  let returnedArray = [];
  var fieldNamesLength = y_field.length;
  let currentKeyIdx = 0;

  Object.keys(dictValues).forEach(function (key) {
    let returnedDict = {};
    returnedDict["id"] = key;
    let dataArray = [];
    returnedDict["data"] = dataArray;
    let colorIdx = currentKeyIdx % fieldNamesLength;
    returnedDict["color"] = colors[colorIdx];
    currentKeyIdx++;
    Object.keys(dictValues[key]).forEach(function (cityKey) {
      let cityDict = {};
      cityDict["x"] = cityKey;

      if (calcType === "sum") {
        var sumValues = dictValues[key][cityKey].reduce(
          (tSum, vals) => tSum + vals,
          0
        );
        cityDict["y"] = sumValues;
      } else if (calcType === "count") {
        var count = dictValues[key][cityKey].length;
        cityDict["y"] = count;
      } else {
        var sumValues = dictValues[key][cityKey].reduce(
          (tSum, vals) => tSum + vals,
          0
        );
        var avgValues = Math.round(sumValues / dictValues[key][cityKey].length);
        cityDict["y"] = avgValues;
      }
      returnedDict["data"].push(cityDict);
    });
    returnedArray.push(returnedDict);
  });

  // console.log(returnedArray);

  return returnedArray;
}

export function getBarFormat(data, x_field, y_field, index_field, calcType) {
  let tempDict = {};

  if (index_field) {
    for (var i = 0; i < data.length; i++) {
      if (!(data[i][x_field] in tempDict)) {
        let xDict = {};
        tempDict[data[i][x_field]] = xDict;
      }
      if (!(data[i][index_field] in tempDict[data[i][x_field]])) {
        let xArray = [];
        tempDict[data[i][x_field]][data[i][index_field]] = xArray;
      }
      tempDict[data[i][x_field]][data[i][index_field]].push(data[i][y_field]);
    }
    let returnedArray = [];
    Object.keys(tempDict).forEach(function (key) {
      let returnedDict = {};
      returnedDict[x_field] = key;
      Object.keys(tempDict[key]).forEach(function (xKey) {
        if (calcType === "average") {
          var calcSum = tempDict[key][xKey].reduce(
            (tSum, vals) => tSum + vals,
            0
          );
          var calcValue = Math.round(calcSum / tempDict[key][xKey].length);
          returnedDict[xKey] = calcValue;
        } else if (calcType === "count") {
          var calcValue = tempDict[key][xKey].length;
          returnedDict[xKey] = calcValue;
        } else {
          var calcSum = tempDict[key][xKey].reduce(
            (tSum, vals) => tSum + vals,
            0
          );
          returnedDict[xKey] = calcSum;
        }
      });
      returnedArray.push(returnedDict);
    });

    return returnedArray;
  } else {
    for (var i = 0; i < data.length; i++) {
      if (!(data[i][x_field] in tempDict)) {
        let xArray = [];
        tempDict[data[i][x_field]] = xArray;
      }
      if (data[i][y_field]) {
        tempDict[data[i][x_field]].push(data[i][y_field]);
      }
    }

    let returnedArray = [];

    Object.keys(tempDict).forEach(function (key) {
      let returnedDict = {};
      returnedDict[x_field] = key;

      if (calcType === "average") {
        var calcSum = tempDict[key].reduce((tSum, vals) => tSum + vals, 0);
        var calcValue = Math.round(calcSum / tempDict[key].length);
        returnedDict[calcType] = calcValue;
      } else if (calcType === "count") {
        var calcValue = tempDict[key].length;
        returnedDict[calcType] = calcValue;
      } else {
        var calcSum = tempDict[key].reduce((tSum, vals) => tSum + vals, 0);
        returnedDict[calcType] = calcSum;
      }
      if (returnedDict[calcType]) {
        returnedArray.push(returnedDict);
      }
    });

    returnedArray.sort((val1, val2) => val1[calcType] - val2[calcType]);
    return returnedArray;
  }
}

export function formatComps(data, listField) {
  const keys = [
    "propertyType",
    "bedrooms",
    "bathrooms",
    "squareFootage",
    "lotSize",
    "price",
    "city",
    "state",
    "id",
    "zipCode",
    "county",
    "mortgagePayment",
  ];

  let flatData = [];

  for (var i = 0; i < data.length; i++) {
    let baseDict = {};

    Object.keys(data[i]).forEach(function (key) {
      if (keys.includes(key)) {
        baseDict[key] = data[i][key];
      }
    });

    baseDict["formattedAddress"] = data[i]["id"].split("-").join(" ");

    let rentArray = data[i][listField];

    if (rentArray) {
      for (var j = 0; j < rentArray.length; j++) {
        let rentalDict = { ...baseDict };

        Object.keys(rentArray[j]).forEach(function (rentKey) {
          if (listField === "comps_rent") {
            var newKey =
              "rentComp" + rentKey.charAt(0).toUpperCase() + rentKey.slice(1);
          } else {
            var newKey =
              "comp" + rentKey.charAt(0).toUpperCase() + rentKey.slice(1);
          }

          rentalDict[newKey] = rentArray[j][rentKey];
        });
        flatData.push(rentalDict);
      }
    }
  }
  return flatData;
}

export function filterData(data, filters) {
  let filteredData = [...data];
  let newFilteredData = [];
  if (filters) {
    Object.keys(filters).forEach((field) => {
      if (filters[field]) {
        for (var i = 0; i < filteredData.length; i++) {
          if (filters[field].includes(filteredData[i][field])) {
            newFilteredData.push(filteredData[i]);
          }
        }
      }
    });
  }
  if (!newFilteredData.length) {
    return data;
  } else {
    return newFilteredData;
  }
}

export function getUniqId(row) {
  let lat = row.latitude;
  let long = row.longitude;
  if (lat < 0) {
    lat = lat * -1;
  } else if (long < 0) {
    long = long * -1;
  }
  return Number(
    lat.toString().split(".").join("") + long.toString().split(".").join("")
  );
}

export function uniqueRecords(dataset) {
  let idArray = [];
  let finalArray = [];

  for (var i = 0; i < dataset.length; i++) {
    if (idArray.indexOf(dataset[i].id) === -1) {
      idArray.push(dataset[i].id);
      finalArray.push(dataset[i]);
    } else {
    }
  }

  return finalArray;
}

export function getUserLocation() {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser"));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (geolocationPositionError) => {
          reject(new Error(geolocationPositionError));
        }
      );
    }
  });
}
