import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserLocation } from "../utilities";

const TokenVerificationWrapper = ({ children }) => {
  const location = useLocation();
  const [userLocation, setUserLocation] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
  const token = localStorage.getItem("token");
  let headerValues = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTONS",
    "Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
  };
  headerValues["Authorization"] = `Bearer ${token}`;

  try {
    useEffect(() => {
      const verifyToken = async () => {
        try {
          const locationData = await getUserLocation();
          setUserLocation(locationData);

          var requestData = {
            currentPage: location.pathname,
            latitude: locationData.latitude,
            longitude: locationData.longitude,
          };
        } catch (error) {
          var requestData = {
            currentPage: location.pathname,
            latitude: null,
            longitude: null,
          };
        }

        // Verify token with backend
        const response = await fetch(baseUrl.concat(`/token_check`), {
          method: "POST",
          headers: headerValues,
          body: JSON.stringify(requestData),
        }).then((resp) => {
          return resp.json();
        });

        const data = response;
        console.log(data);
        // vlaidate that the token exists in the payload and replace the existing token
        if ("token" in data[0]) {
          localStorage.removeItem("token");
          localStorage.setItem("token", data[0]["token"]);
        }
      };
      verifyToken();
    }, []);
  } catch (error) {
    console.error("Error verifying token:", error);
  }
  return <>{children}</>;
};

export default TokenVerificationWrapper;
