import React from "react";
import { useState } from "react";
import { ProSidebar, Menu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import Item from "./Item";

// const Item = ({ title, to, icon, selected, setSelected }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <MenuItem
//       active={selected === title}
//       style={{ color: colors.grey[100] }}
//       onClick={() => setSelected(title)}
//       icon={icon}
//     >
//       <Typography>{title}</Typography>
//       <Link to={to} />
//     </MenuItem>
//   );
// };

const MenuList = ({ setOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  //   const [isCollapsed, setIsCollapsed] = useState(false);
  //   const [selected, setSelected] = useState("Dashboard");
  //   const [isSmallScreen, setIsSmallScreen] = useState(false);

  //   // Use useMediaQuery to listen for changes in screen size
  //   const isSmall = useMediaQuery("(max-width:600px)");

  //   // Update the state based on the screen size
  //   if (isSmall !== isSmallScreen) {
  //     setIsSmallScreen(isSmall);
  //     setIsCollapsed(true);

  //   }

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        // "& .pro-inner-item": {
        //   padding: "5px 25px 5px 20px !important",
        // },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar width="100vw">
        <Menu>
          <Box>
            {/* <Box paddingLeft={isCollapsed ? undefined : "10%"}> */}

            <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Boards
            </Typography>

            <Item
              title="Investment Analysis Dashboard"
              to="/"
              icon={<AnalyticsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Item
              title="Area Analysis Dashboard"
              to="/area-dashboard"
              icon={<AnalyticsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />

            <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Tables
            </Typography>

            <Item
              title="For Sale Comp Data"
              to="/sale-comps"
              icon={<TableChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Item
              title="Rent Comp Data"
              to="/rent-comps"
              icon={<TableChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Item
              title="Investment Analysis Data"
              to="/analysis"
              icon={<TableChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>

            <Item
              title="Bar Chart Analysis"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Item
              title="Pie Chart Analysis"
              to="/pie"
              icon={<PieChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Item
              title="Line Chart Analysis"
              to="/line-rental"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />
            <Item
              title="Map Analysis"
              to="/map"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              setOpen={setOpen}
            />

            {/* <Typography
              variant="h6"
              colors={colors.grey[600]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}

            {/* <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ"
              to="/faq"
              icon={<HelpOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default MenuList;
