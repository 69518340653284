import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingComponent = ({ circleColor }) => {
  return (
    <Box sx={{ color: circleColor }} display="flex" justifyContent="center">
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default LoadingComponent;
