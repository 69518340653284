import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { DataGrid } from "@mui/x-data-grid";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import { getCurrency, getPercent, getPositiveCashFlow } from "../utilities";
import CustomToolbar from "./CustomGridToolbar";
import { getUniqId } from "../utilities";

const TopProperty = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "id",
      headerName: "Property Address",
      width: 300,
      // flex: 1,
      description: "The URL of the Zillow listing of the target property",
      renderCell: (params) => (
        <Button
          variant="contained"
          display="flex"
          onClick={() => {
            window.open(`https://www.zillow.com/homes/${params.value}_rb`);
          }}
          sx={{ background: colors.greenAccent[500] }}
          endIcon={<LaunchOutlinedIcon />}
        >
          <Typography fontWeight="bold">
            {params.value.split(",")[0].split("-").join(" ")}
          </Typography>
        </Button>
      ),
    },
    {
      field: "propertyType",
      headerName: "Property Type",
      description: "The type of property",
      width: 175,
    },

    {
      field: "netOpIncomeAnnual",
      headerName: "NOI Annual",
      valueFormatter: (params) => getCurrency(params.value),
      width: 175,
      description:
        "Gross Rent Revenue - Operating Expenses (property taxes, insurance, and maintenance costs)",
    },
    {
      field: "capRateCostsLow",
      headerName: "Cap Rate Low",
      valueFormatter: (params) => getPercent(params.value),
      width: 175,
      description:
        "(Annual NOI)/ (discounted purchase price + low estimate of rehab costs)",
    },
    {
      field: "capRateCostsHigh",
      headerName: "Cap Rate High",
      valueFormatter: (params) => getPercent(params.value),
      width: 175,
      description:
        "(Annual NOI)/ (discounted purchase price + high estimate of rehab costs)",
    },
    {
      field: "netIncomeAfterFinancingAnnual",
      headerName: "NIAF (Annual)",
      valueFormatter: (params) => getCurrency(params.value),
      width: 175,
      description: " Annual NOI - (Annual mortgage payment)",
      // type: "number",
    },
    {
      field: "cashOnCashReturn",
      headerName: "CoC Return",
      valueFormatter: (params) => getPercent(params.value),
      width: 175,
      description: "NIAF (Annual) / Down Payment",
    },
    {
      field: "totalPaymentMonthly",
      headerName: "Total Monthly Payment",
      valueFormatter: (params) => getCurrency(params.value),

      description:
        "Total monthly payment including insurance, maintenance, property taxes, and estimated insurance",
      width: 200,
    },
    {
      field: "comps_rent_avg",
      headerName: "Comps Rent Avg",
      width: 175,
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "The Rent trimmed mean of the closest 5 comps to the property conisdering the same count of bath and bedrooms",
    },
  ];

  return (
    <Box height="100%" width="100%">
      <DataGrid
        slots={{ toolbar: CustomToolbar }}
        rows={getPositiveCashFlow(data)}
        columns={columns}
        getRowId={getUniqId}
        initialState={{
          sorting: {
            sortModel: [
              { field: "netIncomeAfterFinancingAnnual", sort: "desc" },
            ],
          },
        }}
      />
    </Box>
  );
};
export default TopProperty;
