import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

const CustomToolbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const grey = colors.grey[300];
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ color: grey }} />
      <GridToolbarFilterButton sx={{ color: grey }} />
      {/* <GridToolbarDensitySelector sx={{ color: color_ }} /> */}
      <GridToolbarExport sx={{ color: grey }} />
    </GridToolbarContainer>
  );
};
export default CustomToolbar;
