import React, { useState, useContext } from "react";
import { PropTypes } from "prop-types";
import {
  TextField,
  Typography,
  Grid,
  Button,
  useTheme,
  InputAdornment,
  createTheme,
  Box,
} from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

async function loginUser(credentials) {
  const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
  // console.log(baseUrl);

  return fetch(baseUrl.concat("/login"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTONS",
      "Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

function LoginThankYou({ setToken }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await loginUser({
        email,
        password,
      });
      if (response[1] == 200) {
        console.log(response);
        localStorage.setItem("token", response[0].token);
        setToken(localStorage.getItem("token"));
        localStorage.setItem("customer_id", response[0].data.customer_id);
      } else {
        console.log(response);
        setErrorMessage(response[0].message);
        setShowError(true);
        console.log(errorMessage);
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Box></Box>

        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
      </Box>
      <Grid container>
        <Grid xs={12} align="center" paddingTop={15}>
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[300] }}
          >
            Thank You for Your Subscription to Propertana!
          </Typography>
          <Typography variant="h4" fontWeight="medium">
            Please Login In and Enjoy!
          </Typography>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            // direction="column"
            justifyContent="center"
            alignItems="center"
            marginTop={{ xs: "5vh", md: "5vh" }}
            height={{ xs: "40vh", md: "35vh" }}
            width={{ xs: "75vw", md: "30vw" }}
            sx={{
              background: colors.primary[400],
            }}
            paddingBottom={1}
            spacing={{ xs: 1, md: 0 }}
          >
            <Grid item xs={12} align="center">
              <Typography fontWeight="bold" variant="h3">
                Log In
              </Typography>
            </Grid>

            <Grid item xs={12} align="center" width="100%">
              <TextField
                sx={{
                  // maxWidth: "20vw",
                  // maxHeight: "4vh",
                  // minWidth: "20vw",
                  // minHeight: "4vh",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.grey[300],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[300],
                    },
                  },
                  "& label.Mui-focused": {
                    color: "white",
                    borderColor: "white",
                  },
                }}
                id="outlined-basic"
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <TextField
                sx={{
                  // maxWidth: "20vw",
                  // maxHeight: "4vh",
                  // minWidth: "20vw",
                  // minHeight: "4vh",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.grey[300],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.greenAccent[300],
                    },
                  },
                  "& label.Mui-focused": {
                    color: "white",
                    borderColor: "white",
                  },
                }}
                id="outlined-password-input"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} align="center">
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  //   maxWidth: "20vw",
                  //   maxHeight: "4vh",
                  //   minWidth: "20vw",
                  //   minHeight: "4vh",
                  background: colors.greenAccent[400],
                  "&:hover": {
                    color: `${colors.greenAccent[300]}!important`,
                    borderColor: `${colors.greenAccent[300]}!important`,
                  },
                  minWidth: "10vw",
                }}
              >
                <Typography fontWeight="bold">Login</Typography>
              </Button>
            </Grid>

            <Grid item marginTop={2}>
              {showError ? (
                <Typography fontSize="13px" color="red">
                  {errorMessage}
                </Typography>
              ) : undefined}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

LoginThankYou.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default LoginThankYou;
