import React, { useState, useRef } from "react";
import Header from "../../components/Header";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LineChartRental from "../../components/LineChartRental";
import BarChart from "../../components/BarChart";
import MapCoordinates from "../../components/MapCoordinates";
import StatBox from "../../components/StatBox";
import TopProperty from "../../components/TopProperty";
import { getPositiveCashFlow, getStatBoxVals } from "../../utilities";
import SingleValBox from "../../components/SingleValBox";
import PieChartDynamic from "../../components/PieChartDynamic";
import useAnalysisData from "../../hooks/analysisHook";
import LoadingComponent from "../../components/LoadingComponent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const DashboardAnalytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filters, setFilters] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });

  const { data, loading, error } = useAnalysisData(inputs);

  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  function filterData(data, filters) {
    let filteredData = [...data];
    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const filteredData = loading ? null : filterData(data, filters);
  const mortg = loading
    ? null
    : getStatBoxVals(filteredData, "mortgagePayment");
  const rentals = loading
    ? null
    : getStatBoxVals(filteredData, "comps_rent_avg");
  const totMortg = loading
    ? null
    : getStatBoxVals(filteredData, "totalPaymentMonthly");
  const expenses = loading
    ? null
    : getStatBoxVals(filteredData, "totalAnnualExpenses", true, "c");
  const noi = loading
    ? null
    : getStatBoxVals(filteredData, "netOpIncomeAnnual", false, "c");
  const rehabLow = loading
    ? null
    : getStatBoxVals(filteredData, "rehabCostsLow", false, "c");
  const rehabHigh = loading
    ? null
    : getStatBoxVals(filteredData, "rehabCostsHigh", false, "c");
  const cashLow = loading
    ? null
    : getStatBoxVals(filteredData, "totalCashLow", false, "c");
  const cashHigh = loading
    ? null
    : getStatBoxVals(filteredData, "totalCashHigh", false, "c");

  const niaf = loading
    ? null
    : getStatBoxVals(filteredData, "netIncomeAfterFinancingAnnual", false, "c");
  const coc = loading
    ? null
    : getStatBoxVals(filteredData, "cashOnCashReturn", false, "p");

  const markers = loading ? data : getPositiveCashFlow(filteredData);

  // const isFullWidth = useMediaQuery((theme) => theme.breakpoints.only("sm"));

  const pdfRef = useRef();
  const downloadPDF = () => {
    const input = pdfRef.current;

    console.log(input);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const ratioW = pdfWidth / imgWidth;
      const ratioH = pdfHeight / imgHeight;
      const ratio = Math.min(ratioW, ratioH);
      const imgX = (pdfWidth - imgWidth * ratio * 0.9) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio * 0.9,
        imgHeight * ratio * 1.1
      );
      pdf.save("Dashboard.pdf");
    });
  };

  // Use useMediaQuery to listen for changes in screen size
  const isSmall = useMediaQuery("(max-width:700px)");
  const isSmallHeight = useMediaQuery("(max-height:500px)");

  // Update the state based on the screen size
  if (isSmall !== isSmallScreen) {
    setIsSmallScreen(isSmall);
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="INVESTMENT ANALYSIS DASHBOARD"
          subtitle="Comprehensive Investment Analysis"
        />
      </Box>
      <Box>
        <Button
          type="submit"
          variant="outlined"
          sx={{
            float: "right",
            background: colors.greenAccent[500],
            "&:hover": {
              color: `${colors.greenAccent[300]}!important`,
              borderColor: `${colors.greenAccent[300]}!important`,
            },
          }}
          onClick={downloadPDF}
        >
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
          <Typography sx={{ fontWeight: "bold" }}>Download Reports</Typography>
        </Button>
      </Box>
      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={true}
        rehabHighCost={true}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}

      <Box ref={pdfRef}>
        <Grid container paddingTop={2}>
          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Mortgage Payment"
                subtitle="Average of Mortgage Payment for Listings"
                min={mortg.min}
                max={mortg.max}
                value={mortg.avg}
              />
            )}
          </Grid>

          <Grid item xs={12} md={0.25} p={0.5}></Grid>

          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Monthly Expenses"
                subtitle="Insurance, Property Taxes, and Maintenance"
                min={expenses.min}
                max={expenses.max}
                value={expenses.avg}
              />
            )}
          </Grid>

          <Grid item xs={12} md={0.25} p={0.5}></Grid>

          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Monthly Payment"
                subtitle="Includes Expenses"
                min={totMortg.min}
                max={totMortg.max}
                value={totMortg.avg}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Rentals Comps"
                subtitle="Average of all Rental Comps"
                min={rentals.min}
                max={rentals.max}
                value={rentals.avg}
              />
            )}
          </Grid>
        </Grid>

        <Grid container paddingTop={2}>
          <Grid item xs={12} md={4.5} backgroundColor={colors.primary[400]}>
            <Grid>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Average Total Monthly Payment by City
              </Typography>
            </Grid>
            <Grid height="55vh">
              {loading ? (
                <LoadingComponent circleColor={colors.blueAccent[300]} />
              ) : (
                <Box
                  height="55vh"
                  display="flex"
                  sx={
                    isSmall
                      ? {
                          bgcolor: colors.primary[400],
                          overflow: "hidden",
                          overflowX: "scroll",
                        }
                      : {
                          bgcolor: colors.primary[400],
                          // overflow: "hidden",
                          // overflowX: "scroll",
                        }
                  }
                >
                  <Box
                    minWidth={{ xs: "220vw", md: "100%" }}
                    maxWidth="100%"
                    height="55vh"
                  >
                    <PieChartDynamic
                      pieData={filteredData}
                      xField={"city"}
                      yField={"totalPaymentMonthly"}
                      calcType={"average"}
                      pieColor={colors.greenAccent}
                      isCurrency={true}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid
            item
            xs={12}
            md={7.25}
            height={{ xs: "60vh" }}
            backgroundColor={colors.primary[400]}
          >
            <Grid>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Average Total Payments, Rental, and Mortgage by City
              </Typography>
            </Grid>
            <Grid>
              {loading ? (
                <LoadingComponent circleColor={colors.blueAccent[300]} />
              ) : (
                <Box
                  height="55vh"
                  display="flex"
                  sx={
                    isSmall
                      ? {
                          bgcolor: colors.primary[400],
                          overflow: "hidden",
                          overflowX: "scroll",
                        }
                      : {
                          bgcolor: colors.primary[400],
                          // overflow: "hidden",
                          // overflowX: "scroll",
                        }
                  }
                >
                  <Box
                    minWidth={{ xs: "175vw", md: "100%" }}
                    maxWidth="100%"
                    height="55vh"
                    // height={{ xs: "50vh", md: "45vh" }}
                  >
                    <LineChartRental
                      isDashboard={true}
                      lineData={filteredData}
                      xField={"city"}
                      yField={[
                        "comps_rent_avg",
                        "totalPaymentMonthly",
                        "mortgagePayment",
                      ]}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={0} p={0.5}></Grid>

        <Grid container paddingTop={2}>
          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Rehab Cost Estimate (Low)"
                subtitle={`Low estimate based on $${inputs["rehabLowCost"]} sqft`}
                min={rehabLow.min}
                max={rehabLow.max}
                value={rehabLow.avg}
              />
            )}
          </Grid>

          <Grid item xs={12} md={0.25} p={0.5}></Grid>

          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Rehab Cost Estimate (High)"
                subtitle={`High estimate based on $${inputs["rehabHighCost"]} sqft`}
                min={rehabHigh.min}
                max={rehabHigh.max}
                value={rehabHigh.avg}
              />
            )}
          </Grid>

          <Grid item xs={12} md={0.25} p={0.5}></Grid>

          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {" "}
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Cash Estimate (Low)"
                subtitle={`${inputs["downPayment"]}% Down Payment + $${inputs["rehabLowCost"]} sqft Rehab Costs`}
                min={cashLow.min}
                max={cashLow.max}
                value={cashLow.avg}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            //   spacing={1}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {" "}
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Average Cash Estimate (High)"
                subtitle={`${inputs["downPayment"]}% Down Payment + $${inputs["rehabHighCost"]} sqft Rehab Costs`}
                min={cashHigh.min}
                max={cashHigh.max}
                value={cashHigh.avg}
              />
            )}
          </Grid>
        </Grid>

        <Grid container paddingTop={2}>
          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Net Operating Income (NOI)"
                subtitle="Gross Rent Revenue - Operating Expenses"
                min={noi.min}
                max={noi.max}
                value={noi.avg}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Net Income After Financing (NIAF)"
                subtitle="NOI - Financing Costs"
                min={niaf.min}
                max={niaf.max}
                value={niaf.avg}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            //   spacing={1}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <StatBox
                title="Cash on Cash Return (CoC)"
                subtitle="Down Payment/ NIAF"
                min={coc.min}
                max={coc.max}
                value={coc.avg}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid xs={12} md={2.8} backgroundColor={colors.primary[400]}>
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <SingleValBox
                title="Count of Cash Flow Positive"
                subtitle="Positive NIAF"
                value={
                  loading ? (
                    <div>LOADING</div>
                  ) : (
                    getPositiveCashFlow(filteredData).length
                  )
                }
                value_color={colors.greenAccent[300]}
              />
            )}
          </Grid>
        </Grid>

        <Grid container paddingTop={2}>
          <Grid
            item
            xs={12}
            md={7.25}
            backgroundColor={colors.primary[400]}
            height="60vh"
          >
            <Grid>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Count of Cash Flow Positive Properties
              </Typography>
            </Grid>
            <Grid>
              {loading ? (
                <LoadingComponent circleColor={colors.blueAccent[300]} />
              ) : (
                <Box
                  height="55vh"
                  display="flex"
                  sx={
                    isSmall
                      ? {
                          bgcolor: colors.primary[400],
                          overflow: "hidden",
                          overflowX: "scroll",
                        }
                      : {
                          bgcolor: colors.primary[400],
                          // overflow: "hidden",
                          // overflowX: "scroll",
                        }
                  }
                >
                  <Box
                    minWidth={{ xs: "200vw", md: "100%" }}
                    maxWidth="100%"
                    height="55vh"
                  >
                    <BarChart
                      isDashboard={true}
                      barData={getPositiveCashFlow(filteredData)}
                      xField={"city"}
                      yField={"id"}
                      calcType={"count"}
                      indexField={null}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={0.25} p={0.5}></Grid>
          <Grid item xs={12} md={4.5} backgroundColor={colors.primary[400]}>
            <Grid>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px" }}
              >
                Average of Cash Flow Positive by City
              </Typography>
            </Grid>
            <Grid height="55vh">
              {loading ? (
                <LoadingComponent circleColor={colors.blueAccent[300]} />
              ) : (
                <Box
                  height="55vh"
                  display="flex"
                  sx={
                    isSmall
                      ? {
                          bgcolor: colors.primary[400],
                          overflow: "hidden",
                          overflowX: "scroll",
                        }
                      : {
                          bgcolor: colors.primary[400],
                          // overflow: "hidden",
                          // overflowX: "scroll",
                        }
                  }
                >
                  <Box
                    minWidth={{ xs: "220vw", md: "100%" }}
                    maxWidth="100%"
                    height="50vh"
                  >
                    <PieChartDynamic
                      pieData={getPositiveCashFlow(filteredData)}
                      xField={"city"}
                      yField={"netIncomeAfterFinancingAnnual"}
                      calcType={"average"}
                      pieColor={colors.redAccent}
                      isCurrency={true}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container paddingTop={2}>
        <Grid item xs={12} backgroundColor={colors.primary[400]}>
          <Grid>
            <Typography
              variant="h3"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "10px" }}
            >
              Top Prospective Properties Analysis
            </Typography>
          </Grid>
          <Grid height="50vh" width="100%">
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <TopProperty data={filteredData} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid paddingTop={2}>
        <Grid backgroundColor={colors.primary[400]}>
          <Grid>
            <Typography
              variant="h3"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "10px" }}
            >
              Property Map with Full Analysis
            </Typography>
          </Grid>
          <Grid height="50vh" width="100%">
            {loading ? (
              <LoadingComponent circleColor={colors.blueAccent[300]} />
            ) : (
              <MapCoordinates markers={markers} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardAnalytics;
