import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  TextField,
  // InputLabel,
  // FormControl,
  // MenuItem,
  // Select,
  // OutlinedInput,
  // ListItemText,
  // Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
// import Checkbox from "@mui/material/Checkbox";
import LoadingComponent from "../../components/LoadingComponent";
import InputAdornment from "@mui/material/InputAdornment";

import {
  // GetZillowUrl,
  getCurrency,
  getPercent,
  dateFormat,
  // getPositiveCashFlow,
} from "../../utilities/index";
import CustomToolbar from "../../components/CustomGridToolbar";
import { tokens } from "../../theme";
import useAnalysisData from "../../hooks/analysisHook";
// import Autocomplete from "@mui/material/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomFilters from "../../components/Filters";
import CalculatedFields from "../../components/CalculatedFields";
import { getUniqId, uniqueRecords } from "../../utilities/index";

const AnalysisList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [filters, setFilters] = useState(false);

  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES), //float
  });

  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: colors.primary[400],
      },
    },
  };

  const { data, loading, error } = useAnalysisData(inputs);

  function filterData(data, filters) {
    let filteredData = [...data];
    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const filteredData = loading ? null : filterData(data, filters);

  const columns = [
    {
      field: "id",
      headerName: "Address",
      width: 250,
      renderCell: (params) => (
        <Button
          variant="contained"
          display="flex"
          onClick={() => {
            window.open(`https://www.zillow.com/homes/${params.value}_rb`);
          }}
          sx={{ background: colors.greenAccent[500] }}
          endIcon={<LaunchOutlinedIcon />}
        >
          <Typography fontWeight="bold">
            {params.value.split(",")[0].split("-").join(" ")}
          </Typography>
        </Button>
      ),
    },
    {
      field: "city",
      headerName: "City",
    },
    { field: "state", headerName: "State" },

    {
      field: "propertyType",
      headerName: "Property Type",
      description: "The type of property",
      width: 150,
    },
    {
      field: "bedrooms",
      headerName: "Beds",
      description: "The number of bedrooms in the property",
    },
    {
      field: "bathrooms",
      headerName: "Baths",
      description: "The number of bathrooms in the property",
    },
    {
      field: "squareFootage",
      headerName: "Target Sqft",
      description: "The square footage of the property",
      width: 150,
    },

    {
      field: "totalPaymentMonthly",
      headerName: "Est Monthly Payment",
      description: `Estimated Monthly Payment considering interest rate of  ${inputs["interestRate"]}%, ${inputs["downPayment"]}% down payment, insurance, taxes, and mortgage costs`,
      valueFormatter: (params) => getCurrency(params.value),
      width: 150,
    },
    {
      field: "listedDate",
      headerName: "Listed Date",
      valueGetter: (params) => dateFormat(params.row.listedDate),
      description: "The initial date of the listing",
      width: 150,
    },
    {
      field: "daysOnMarket",
      headerName: "Days on Market",
      description: "The days on the market the property has been listed",
      width: 150,
    },
    {
      field: "mortgagePayment",
      headerName: "Mortgage Payment",
      valueFormatter: (params) => getCurrency(params.value),
      description: `The base mortgage payment without any additional expenses, ${inputs["loanLength"]} year terms, ${inputs["interestRate"]}% rate`,
      width: 150,
    },
    {
      field: "offerPrice",
      headerName: "Offer Price",
      valueFormatter: (params) => getCurrency(params.value),
      description: `The offer considering a discount rate of ${inputs["offerDiscount"]}%`,
      width: 150,
    },
    {
      field: "downPayment",
      headerName: "Down Payment",
      valueFormatter: (params) => getCurrency(params.value),
      description: `The amount of the down payment considering a ${inputs["downPayment"]}% down payment`,
      width: 150,
    },
    {
      field: "mortgageAmount",
      headerName: "Mortgage Amount",
      valueFormatter: (params) => getCurrency(params.value),
      description: `The mortgage amount considering a ${inputs["offerDiscount"]}% discount on the listing price and ${inputs["downPayment"]}% down payment`,
      width: 150,
    },
    {
      field: "rehabCostsLow",
      headerName: "Rehab Costs Low",
      valueFormatter: (params) => getCurrency(params.value),
      description: `Rehab Costs considering a Low estimate of $${inputs["rehabLowCost"]} per sqft`,
      width: 150,
    },
    {
      field: "rehabCostsHigh",
      headerName: "Rehab Costs High",
      valueFormatter: (params) => getCurrency(params.value),
      description: `Rehab Costs considering a High estimate of $${inputs["rehabHighCost"]} per sqft`,
      width: 150,
    },
    {
      field: "comps_rent_avg",
      headerName: "Comps Rent Avg",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "The Rent trimmed mean of the closest 5 comps to the property conisdering the same count of bath and bedrooms",
      width: 150,
    },
    {
      field: "comps_listings_avg",
      headerName: "Comps Price Avg",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "The Property trimmed mean of the closest 5 comps of currently listed and recently listed within the last 180 days to the property conisdering the same count of bath and bedrooms",
      width: 150,
    },
    {
      field: "totalCostsLow",
      headerName: "Total Cost Low",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "Total cost of both the discounted offer price and the low estimate of rehab costs",
      width: 150,
    },
    {
      field: "totalCashLow",
      headerName: "Total Cash Low",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "The total cash used for the property including the low estimate of rehab costs and down payment",
      width: 150,
    },
    {
      field: "totalCostsHigh",
      headerName: "Total Costs High",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "Total cost of both the discounted offer price and the high estimate of rehab costs",
      width: 150,
    },
    {
      field: "totalCashHigh",
      headerName: "Total Cash High",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "The total cash used for the property including the low estimate of rehab costs and down payment",
      width: 150,
    },
    {
      field: "insuranceCostsYear",
      headerName: "Insurance Cost",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "The insurance costs based on a rate that is calculated base on the (average insurance)/(average property size)",
      width: 150,
    },
    {
      field: "maintCostsYear",
      headerName: "Maintenance Cost",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "Rental maintenace costs considering the 1.5 multiple of the average rental comps for the property",
      width: 150,
    },
    {
      field: "propertyTaxesYear",
      headerName: "Property Taxes",
      valueFormatter: (params) => getCurrency(params.value),
      description: `Property taxes considering a ${inputs["propertyTaxes"]}% property rate`,
      width: 150,
    },
    {
      field: "totalAnnualExpenses",
      headerName: "Total Expenses",
      valueFormatter: (params) => getCurrency(params.value),
      description:
        "Total annual expenses considering property taxes, insurance, and maintenance costs",
      width: 150,
    },
    {
      field: "netOpIncomeAnnual",
      headerName: "NOI",
      valueFormatter: (params) => getCurrency(params.value), //params.value.toFixed(2))
      description:
        "Gross Rent Revenue - Operating Expenses (property taxes, insurance, and maintenance costs)",
      width: 150,
    },
    {
      field: "capRateCostsLow",
      headerName: "Cap Rate Low",
      valueFormatter: (params) => getPercent(params.value),
      description:
        "(Annual NOI)/ (discounted purchase price + low estimate of rehab costs)",
      width: 150,
    },
    {
      field: "capRateCostsHigh",
      headerName: "Cap Rate High",
      valueFormatter: (params) => getPercent(params.value),
      description:
        "(Annual NOI)/ (discounted purchase price + high estimate of rehab costs)",
      width: 150,
    },
    {
      field: "netIncomeAfterFinancingAnnual",
      headerName: "NIAF (Annual)",
      valueFormatter: (params) => getCurrency(params.value),
      description: " Annual NOI - (Annual mortgage payment)",
      width: 150,
      // type: "number",
    },
    {
      headerName: "NIAF (Monthly)",
      valueGetter: (params) => params.row.netIncomeAfterFinancingAnnual / 12,
      valueFormatter: (params) => getCurrency(params.value), //.toFixed(2)),
      description: "Average Monthly NOI - Monthly mortgage",
      width: 150,
    },
    {
      field: "cashOnCashReturn",
      headerName: "CoC Return",
      valueFormatter: (params) => getPercent(params.value),
      description: "NIAF (Annual) / Down Payment",
      width: 150,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Investment Analysis Data"
        subtitle="Analysis of Individual Properties, including Investment Analysis calculations"
      />
      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={true}
        rehabHighCost={true}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}

      <Grid container padding={1}></Grid>

      <Box m="40px 0 0 0" height="75vh" width="100%">
        {loading ? (
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        ) : (
          <DataGrid
            slots={{ toolbar: CustomToolbar }}
            rows={uniqueRecords(filteredData)}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(row) => row.id}
            columnBuffer={3}
            columnThreshold={3}
          />
        )}
      </Box>
    </Box>
  );
};
export default AnalysisList;
