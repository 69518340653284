import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  Box,
  FormControl,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LoadingComponent from "./LoadingComponent";
import { tokens } from "../theme";

async function manageSubscription(customerId) {
  const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
  // console.log(baseUrl);

  return fetch(baseUrl.concat("/create_portal_session"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTONS",
      "Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
    },
    body: JSON.stringify(customerId),
  }).then((data) => data.json());
}

const ProfileDropdownMenu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const history = useHistory();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRedirectToPortal = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await manageSubscription({
        customer_id: localStorage.getItem("customer_id"),
      });
      if (response[1] == 200) {
        console.log(response);
        setLoading(false);
        window.location.href = response[0].url;
        // localStorage.setItem("customer_id", response[0].data.customer_id);
      } else {
        setLoading(false);
        console.log(response);
        setErrorMessage(response[0].message);
        setShowError(true);
        console.log(errorMessage);
      }
    } catch (error) {
      setLoading(false);
      console.error("Manage Subscription failed", error);
    }
  };

  return (
    <Box>
      <IconButton
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <PersonOutlinedIcon />
      </IconButton>
      <FormControl>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          sx={{
            ".MuiMenu-paper": {
              backgroundColor: colors.primary[400],
            },
            ".MuiMenu-list": {
              color: colors.greenAccent[300],
            },
          }}
        >
          {loading ? (
            <LoadingComponent circleColor={colors.greenAccent[300]} />
          ) : (
            <MenuItem
              onClick={handleRedirectToPortal}
              sx={{ fontWeight: "bold" }}
            >
              Manage Subsciption
            </MenuItem>
          )}
        </Menu>
      </FormControl>
    </Box>
  );
};

export default ProfileDropdownMenu;
