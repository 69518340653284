import React from "react";
import { useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import Header from "../../components/Header";
import LoadingComponent from "../../components/LoadingComponent";
import MapCoordinates from "../../components/MapCoordinates";
import { tokens } from "../../theme";
import useAnalysisData from "../../hooks/analysisHook";
import CalculatedFields from "../../components/CalculatedFields";
import CustomFilters from "../../components/Filters";

const Map = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [filters, setFilters] = useState({});
  const [inputs, setInputs] = useState({
    downPayment: parseInt(process.env.REACT_APP_DOWN_PAYMENT),
    interestRate: parseInt(process.env.REACT_APP_INTEREST_RATE),
    loanLength: parseInt(process.env.REACT_APP_LOAN_LENGTH),
    insurancePerSqFt: parseFloat(process.env.REACT_APP_INSURANCE_PER_SQ_FT), // float
    daysOldMax: parseInt(process.env.REACT_APP_DAYS_OLD_MAX),
    offerDiscount: parseInt(process.env.REACT_APP_OFFER_DISCOUNT),
    rehabLowCost: parseInt(process.env.REACT_APP_REHAB_LOW_COST),
    rehabHighCost: parseInt(process.env.REACT_APP_REHAB_HIGH_COST),
    propertyTaxes: parseFloat(process.env.REACT_APP_PROPERTY_TAXES),
  });
  const fieldOptions = [
    ["propertyType", "Property Type"],
    ["bedrooms", "Bedrooms"],
    ["bathrooms", "Bathrooms"],
    ["city", "City"],
    ["state", "State"],
    ["zipCode", "Zip Code"],
    ["county", "County"],
  ];

  const { data, loading, error } = useAnalysisData(inputs);

  function filterData(data, filters) {
    let filteredData = [...data];
    let newFilteredData = [];
    if (filters) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          for (var i = 0; i < filteredData.length; i++) {
            if (filters[field].includes(filteredData[i][field])) {
              newFilteredData.push(filteredData[i]);
            }
          }
        }
      });
    }
    if (!newFilteredData.length) {
      return data;
    } else {
      return newFilteredData;
    }
  }

  const filteredData = loading ? null : filterData(data, filters);

  return (
    <Box m="20px">
      <Header title="Property Map" subtitle="" />

      <CalculatedFields
        downPayment={true}
        interestRate={true}
        loanLength={true}
        insurancePerSqFt={true}
        daysOldMax={true}
        offerDiscount={true}
        rehabLowCost={true}
        rehabHighCost={true}
        propertyTaxes={true}
        loading={loading}
        inputs={inputs}
        setInputs={setInputs}
      />
      <Grid container padding={1}></Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          sx={{ bgcolor: colors.primary[400] }}
        >
          <LoadingComponent circleColor={colors.greenAccent[400]} />
        </Grid>
      ) : (
        <CustomFilters
          fieldOptions={fieldOptions}
          filters={filters}
          setFilters={setFilters}
          data={data}
          loading={loading}
        />
      )}

      <Grid container padding={1}></Grid>

      <Grid height="75vh">
        {loading ? (
          <LoadingComponent circleColor={colors.blueAccent[300]} />
        ) : (
          <Box
            height="75vh"
            display="flex"
            sx={{
              bgcolor: colors.primary[400],
              overflow: "hidden",
              overflowX: "scroll",
              overflowY: "scroll",
            }}
          >
            <Box
              minWidth={{ xs: "100vw", md: "100vw" }}
              maxWidth="100%"
              height={{ xs: "70", md: "70vh" }}
            >
              <MapCoordinates markers={filteredData} />
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default Map;
