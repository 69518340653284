import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";

const StatBox = ({
  title,
  subtitle,
  min,
  max,
  value,
  value_color = undefined,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (value_color === undefined) {
    value_color = colors.blueAccent[300];
  }

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" padding={1}>
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: colors.grey[300] }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ color: value_color }}
          align="center"
        >
          {value ? value : 0}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" padding={1}>
        <Box>
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.greenAccent[400] }}
            >
              MIN
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {min ? min : 0}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.redAccent[400] }}
            >
              MAX
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {max ? max : 0}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
