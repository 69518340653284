import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  TextField,
} from "@mui/material";

import LoadingComponent from "../components/LoadingComponent";
import InputAdornment from "@mui/material/InputAdornment";
import { tokens } from "../theme";

const CalculatedFields = ({
  downPayment = false,
  interestRate = false,
  loanLength = false,
  insurancePerSqFt = false,
  daysOldMax = false,
  offerDiscount = false,
  rehabLowCost = false,
  rehabHighCost = false,
  propertyTaxes = false,
  loading = false,
  setInputs,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const [tempDownPayment, setTempDownPayment] = useState(30);
  // const [tempInterestRate, setTempInterestRate] = useState(6);
  // const [tempLoanLength, setTempLoanLength] = useState(30);
  // const [tempInsurancePerSqFt, setTempInsurancePerSqFt] = useState(1);
  // const [tempDaysOldMax, setTempDaysOldMax] = useState(30);
  // const [tempOfferDiscount, setOfferDiscount] = useState(5);
  // const [tempRehabLowCost, setTempRehabLowCost] = useState(20);
  // const [tempRehabHighCost, setTempRehabHighCost] = useState(35);
  // const [tempPropertyTaxes, setTempPropertyTaxes] = useState(0.6);

  const [tempDownPayment, setTempDownPayment] = useState(
    parseInt(process.env.REACT_APP_DOWN_PAYMENT)
  );
  const [tempInterestRate, setTempInterestRate] = useState(
    parseInt(process.env.REACT_APP_INTEREST_RATE)
  );
  const [tempLoanLength, setTempLoanLength] = useState(
    parseInt(process.env.REACT_APP_LOAN_LENGTH)
  );
  const [tempInsurancePerSqFt, setTempInsurancePerSqFt] = useState(
    parseInt(process.env.REACT_APP_INSURANCE_PER_SQ_FT)
  );
  const [tempDaysOldMax, setTempDaysOldMax] = useState(
    parseInt(process.env.REACT_APP_DAYS_OLD_MAX)
  );
  const [tempOfferDiscount, setOfferDiscount] = useState(
    parseInt(process.env.REACT_APP_OFFER_DISCOUNT)
  );
  const [tempRehabLowCost, setTempRehabLowCost] = useState(
    parseInt(process.env.REACT_APP_REHAB_LOW_COST)
  );
  const [tempRehabHighCost, setTempRehabHighCost] = useState(
    parseInt(process.env.REACT_APP_REHAB_HIGH_COST)
  );
  const [tempPropertyTaxes, setTempPropertyTaxes] = useState(
    parseFloat(process.env.REACT_APP_PROPERTY_TAXES)
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    let updated = {};

    updated["interestRate"] = tempInterestRate;

    updated["downPayment"] = tempDownPayment;

    updated["loanLength"] = tempLoanLength;

    updated["insurancePerSqFt"] = tempInsurancePerSqFt;

    updated["daysOldMax"] = tempDaysOldMax;

    updated["offerDiscount"] = tempOfferDiscount;

    updated["propertyTaxes"] = tempPropertyTaxes;

    updated["rehabLowCost"] = tempRehabLowCost;

    updated["rehabHighCost"] = tempRehabHighCost;

    console.log(updated);

    setInputs(updated);
  };

  return (
    <Box>
      <Grid
        container
        backgroundColor={colors.primary[400]}
        paddingTop={1}
        justifyContent="center"
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          Calculated Fields
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          backgroundColor={colors.primary[400]}
          direction="column"
          justifyContent="space-between"
          paddingTop={1}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            padding={1}
            spacing={{ xs: 2, md: 2 }}
          >
            {downPayment ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Down Payment"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={tempDownPayment}
                  onChange={(e) => setTempDownPayment(e.target.value)}
                />
              </Grid>
            ) : undefined}
            {interestRate ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Interest Rate"
                  value={tempInterestRate}
                  onChange={(e) => setTempInterestRate(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {loanLength ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Loan Length"
                  value={tempLoanLength}
                  onChange={(e) => setTempLoanLength(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          years
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {insurancePerSqFt ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Insurance Per SqFt"
                  value={tempInsurancePerSqFt}
                  onChange={(e) => setTempInsurancePerSqFt(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{ color: colors.grey[100] }}>
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          per sqft
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {daysOldMax ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Days Listed Max"
                  value={tempDaysOldMax}
                  onChange={(e) => setTempDaysOldMax(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          days
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {offerDiscount ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Offer Discount"
                  value={tempOfferDiscount}
                  onChange={(e) => setOfferDiscount(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {rehabLowCost ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Rehab Low"
                  value={tempRehabLowCost}
                  onChange={(e) => setTempRehabLowCost(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{ color: colors.grey[100] }}>
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          per sqft
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {rehabHighCost ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Rehab High"
                  value={tempRehabHighCost}
                  onChange={(e) => setTempRehabHighCost(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography sx={{ color: colors.grey[100] }}>
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          per sqft
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
            {propertyTaxes ? (
              <Grid item xs={6} md={12 / 9}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.grey[200],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: colors.greenAccent[400],
                      },
                    },
                    "& label.Mui-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                  }}
                  id="outlined-password-input"
                  label="Property Tax Rate"
                  value={tempPropertyTaxes}
                  onChange={(e) => setTempPropertyTaxes(e.target.value)}
                  onBlur={(e) =>
                    setTempPropertyTaxes(parseFloat(e.target.value))
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: colors.grey[100] }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : undefined}
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            paddingTop={3}
            paddingBottom={2}
          >
            {/* <Grid item xs={4.5}></Grid> */}
            <Grid item xs={12} align="center">
              {loading ? (
                <LoadingComponent circleColor={colors.greenAccent[400]} />
              ) : (
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{
                    maxWidth: "20vw",
                    maxHeight: "4vh",
                    minWidth: "20vw",
                    minHeight: "4vh",
                    background: colors.greenAccent[500],
                    "&:hover": {
                      color: `${colors.greenAccent[300]}!important`,
                      borderColor: `${colors.greenAccent[300]}!important`,
                      // background: colors.blueAccent[300],
                    },
                  }}
                >
                  <Typography fontWeight="bold">Update</Typography>
                </Button>
              )}
            </Grid>
            {/* <Grid item xs={4.5}></Grid> */}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CalculatedFields;
