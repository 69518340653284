import { useEffect, useState } from "react";

async function getRentalData(values) {
  const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;
  let headerValues = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTONS",
    "Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Authorization,Access-Control-Allow-Headers",
  };
  const token = localStorage.getItem("token");

  headerValues["Authorization"] = `Bearer ${token}`;

  const {
    downPayment,
    interestRate,
    loanLength,
    insurancePerSqFt,
    daysOldMax,
    offerDiscount,
    propertyTaxes,
  } = values;

  return fetch(
    baseUrl.concat(
      `/rental/data?filter.root_city=phoenix&interest_rate=${interestRate}&loan_length=${loanLength}&days_old_max=${daysOldMax}&insurance_per_sqft=${insurancePerSqFt}&down_payment=${
        downPayment / 100
      }&offer_discount=${offerDiscount / 100}&property_taxes=${
        propertyTaxes / 100
      }`
    ),

    {
      method: "GET",
      headers: headerValues,
    }
  ).then((data) => data.json());
}

const useRentalData = (defaultValues) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getRentalData(defaultValues);
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [defaultValues]);

  return { data, loading, error };
};

export default useRentalData;
