import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useState } from "react";
import React from "react";
import "@ashwamegh/react-link-preview/dist/index.css";
import ZillowImage from "./ZillowImage";
import { GetZillowUrl } from "../utilities";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { getCurrency } from "../utilities";

const MapCoordinates = ({ props, markers }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const image = "/zillow.png";

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const [mapRef, setMapRef] = useState(null);

  // const data = data;

  const onMapLoad = (mapRef) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((item) => {
      bounds.extend({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
      });
      return item.id;
    });

    mapRef.fitBounds(bounds);
    setMapRef(mapRef);
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const onUnmount = React.useCallback(function callback(mapRef) {
    setMapRef(null);
  }, []);

  return !isLoaded ? (
    <h1>Loading...</h1>
  ) : (
    <GoogleMap
      onLoad={onMapLoad}
      onUnmount={onUnmount}
      mapContainerStyle={containerStyle}
      zoom={10}
    >
      {markers.map(
        (
          {
            latitude,
            longitude,
            id,
            bedrooms,
            bathrooms,
            lotSize,
            price,
            compsAvg,
            offerPrice,
            mortgageAmount,
            mortgagePayment,
            totalPaymentMonthly,
            insuranceCostsYear,
            maintCostsYear,
            propertyTaxesYear,
            compsRentAvg,
            squareFootage,
            rehabCostsLow,
            rehabCostsHigh,
            totalCashHigh,
            totalCashLow,
            netOpIncomeAnnual,
            capRateCostsLow,
            capRateCostsHigh,
            netIncomeAfterFinancingAnnual,
            cashOnCashReturn,
          },
          index
        ) => (
          <MarkerF
            position={{ lat: latitude, lng: longitude }}
            key={index}
            onClick={() => handleActiveMarker(index)}
          >
            {activeMarker === index ? (
              <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                <Paper
                  sx={{
                    p: 1,
                    margin: "auto",
                    // maxWidth: 1600,
                    // minWidth: 600,
                    // maxHeight: 600,
                    flexGrow: 1,
                    backgroundColor: colors.grey[900],
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 0, md: 2 }}>
                      <Grid item xs={0.1} md={0.75}></Grid>

                      <Grid item sx={{ p: 0 }} xs={5.9} md={5} align="center">
                        <Grid
                          container
                          width={{ xs: "20vw", md: "10vw" }}
                          height={{ xs: "20vw", md: "10vw" }}
                          sx={{
                            p: 2,
                            // width: "20vh",
                            // height: "20vh",
                            justifyContent: "center",
                            // marginTop: "5px",
                            marginRight: "70px",
                            alignItems: "center",
                          }}
                        >
                          <ZillowImage
                            url={`https://www.zillow.com/homes/${id}_rb`}
                          />
                        </Grid>

                        <Grid
                          sx={{
                            p: 0.5,
                            border: `2px solid ${colors.grey[400]}`,
                            marginTop: "-10px",
                          }}
                        >
                          <Grid
                            item
                            xs
                            sx={{
                              p: 0,
                              border: `2px solid ${colors.grey[400]}`,
                            }}
                          >
                            <Typography
                              variant={{ xs: "h5", md: "h4" }}
                              align="center"
                              component="div"
                              fontWeight="bold"
                            >
                              Analysis Details
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Rehab Costs (Low): {getCurrency(rehabCostsLow)}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Rehab Costs (High): {getCurrency(rehabCostsHigh)}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Total Cash (Low): {getCurrency(totalCashLow)}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Total Cash (High): {getCurrency(totalCashHigh)}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Net Op Income (Annual):{" "}
                              {getCurrency(netOpIncomeAnnual)}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Cap Rate (Low):{" "}
                              {`${(capRateCostsLow * 100).toFixed(2)}%`}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Cap Rate (High):{" "}
                              {`${(capRateCostsHigh * 100).toFixed(2)}%`}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Cash on Cash Return:{" "}
                              {`${(cashOnCashReturn * 100).toFixed(2)}%`}
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              NIAF (Annual):{" "}
                              {getCurrency(netIncomeAfterFinancingAnnual)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.1} md={0.75}></Grid>

                      <Grid xs={5.9} md={5} item align="center">
                        <Grid
                          item
                          xs
                          align="center"
                          sx={{
                            p: 0.5,
                            border: `2px solid ${colors.grey[400]}`,
                          }}
                        >
                          <Typography
                            variant={{ xs: "h5", md: "h4" }}
                            component="div"
                            fontWeight="bold"
                          >
                            {GetZillowUrl(id, colors.greenAccent[400])}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          sx={{
                            p: 0.5,
                            border: `2px solid ${colors.grey[400]}`,
                            marginTop: "15px",
                          }}
                        >
                          <Grid
                            item
                            xs
                            sx={{
                              p: 0,
                              border: `2px solid ${colors.grey[400]}`,
                            }}
                          >
                            <Typography
                              variant={{ xs: "h5", md: "h4" }}
                              align="center"
                              component="div"
                              fontWeight="bold"
                            >
                              Listing Details
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              List Price: {getCurrency(price)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Beds: {bedrooms}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Baths: {bathrooms}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Lot Size:{" "}
                              {getCurrency(lotSize).split("$")[1] + " sqft"}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Home Size:{" "}
                              {getCurrency(squareFootage).split("$")[1] +
                                " sqft"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs
                            sx={{
                              p: 0,
                              border: `2px solid ${colors.grey[400]}`,
                            }}
                          >
                            <Typography
                              variant={{ xs: "h5", md: "h4" }}
                              align="center"
                              component="div"
                              fontWeight="bold"
                            >
                              Estimates
                            </Typography>
                          </Grid>

                          <Grid item xs marginTop="4px">
                            <Typography gutterBottom component="div">
                              Comps Average: {getCurrency(compsAvg)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Offer: {getCurrency(offerPrice)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Mortgage: {getCurrency(mortgageAmount)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Mortgage Payment: {getCurrency(mortgagePayment)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Insurance Monthly:{" "}
                              {getCurrency(insuranceCostsYear / 12)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Maintenance Monthly:{" "}
                              {getCurrency(maintCostsYear / 12)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Property Taxes:{" "}
                              {getCurrency(propertyTaxesYear / 12)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Monthly Payment:{" "}
                              {getCurrency(totalPaymentMonthly)}
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <Typography gutterBottom component="div">
                              Rental Comps: {getCurrency(compsRentAvg)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>

                {/* <Box style={{ borderRadius: '16px', borderColor: colors.grey[200], background: colors.primary[400],display:"flex",alignItems:"center"}}>
                    
                    <Box style={{borderRadius: '16px', borderColor: colors.grey[200]}}>
                    <ZillowImage url={`https://www.zillow.com/homes/${id}_rb`}/>
                    </Box>
                        
                    <Box style={{borderRadius: '16px', borderColor: colors.grey[200], padding:'5px' }}>
                        <Typography variant="h3">
                        {GetZillowUrl(id)}
                        </Typography>
                    </Box>

                    <Box>

                        <Typography variant="h5">
                        Beds: {bedrooms}
                        </Typography>

                        <Typography variant="h5">
                        Baths: {bathrooms} 
                        </Typography>

                        <Typography variant="h5">
                        Lot Size: {lotSize}
                        </Typography>

                        <Typography variant="h5">
                        List Price: {getCurrency(price)}
                        </Typography>

                        <Typography variant="h5">
                        Comps Average: {getCurrency(compsAvg)}
                        </Typography>

                        <Typography variant="h5">
                        Lot Size: {lotSize}
                        </Typography>

                    </Box>
                       
                </Box> */}
              </InfoWindowF>
            ) : null}
          </MarkerF>
        )
      )}
    </GoogleMap>
  );
};

export default MapCoordinates;
