import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Item = ({ title, to, icon, selected, setSelected, setOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    setSelected(title);
    setOpen(false);
  };

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      //   onClick={() => setSelected(title) && onLinkClick}
      onClick={handleClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

export default Item;
