import React from "react";
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { getBarFormat } from "../utilities";
// import { mockBarData as data } from "../data/mockBarData";

function GetColor(color) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMultiple = color * 100;
  return colors.greenAccent[colorMultiple];
}

const BarChart = ({
  isDashboard = false,
  barData,
  xField,
  yField,
  calcType,
  indexField,
  isCurrency = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const data = getBarFormat(barData, xField, yField, indexField, calcType);

  let keysArray = [];
  if (indexField) {
    for (var i = 0; i < data.length; i++) {
      Object.keys(data[i]).forEach(function (key) {
        if (key !== xField) {
          if (!keysArray.includes(key)) {
            keysArray.push(key);
          }
        }
      });
    }
  }

  const ref = {
    bedrooms: "Bedroooms",
    bathrooms: "Bathrooms",
    squareFootage: "Square Footage",
    yearBuilt: "Year Built",
    price: "Price",
    mortgagePayment: "Mortgage Payment",
    offerPrice: "Offer Price",
    downPayment: "Down Payment",
    mortgageAmount: "Mortgage Amount",
    rehabCostsLow: "Rehab Costs (Low Estimate)",
    rehabCostsHigh: "Rehab Costs (Low Estimate)",
    compsRentAvg: "Comps Rent Avg",
    compsAvg: "Comps Avg",
    totalPaymentMonthly: "Total Monthly Payment",
    netOpIncomeAnnual: "Net Op Income",
    netIncomeAfterFinancingAnnual: "NIAF",
    average: "Average",
    count: "Count",
    sum: "Sum",
    city: "City",
    zipCode: "Zip Code",
    propertyType: "Property Type",
  };

  return (
    <ResponsiveBar
      data={data}
      motionConfig="slow"
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            background: colors.primary[400],
          },
        },
      }}
      keys={indexField ? keysArray : [calcType]}
      indexBy={xField}
      valueFormat={isCurrency ? ">$," : undefined}
      margin={{
        top: isDashboard ? 20 : 50,
        right: isDashboard ? 30 : 30,
        bottom: 50,
        left: isDashboard ? 50 : 65,
      }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      // colors={(d) => GetColor(d.data.color)}
      colors={[
        colors.greenAccent[300],
        colors.greenAccent[400],
        colors.greenAccent[500],
        colors.greenAccent[600],
        colors.blueAccent[300],
        colors.blueAccent[400],
        colors.blueAccent[500],
        colors.blueAccent[600],
      ]}
      colorBy={indexField ? "id" : "indexValue"}
      borderColor={{
        from: "background",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 15,
        legend: isDashboard ? undefined : `${ref[xField]}`,
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : `${ref[yField]}`,
        legendPosition: "middle",
        legendOffset: -55,
        truncateTickAt: 0,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={colors.primary["400"]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );
};

export default BarChart;
